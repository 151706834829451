import {
  Children,
  cloneElement,
  isValidElement,
  JSXElementConstructor,
  ReactElement,
  useState,
} from "react";
import Wrapper from "./ContentBlockWrapper.style";

type ContentBlockChild =
  | boolean
  | ReactElement<ContentBlockProps, string | JSXElementConstructor<any>>;

type ContentBlockWrapperProps = {
  children: ContentBlockChild | ContentBlockChild[];
};

type ContentBlockState = {
  number?: number;
  complete?: boolean;
};

export const ContentBlockWrapper = ({ children }: ContentBlockWrapperProps) => {
  const stateBuilder = (): ContentBlockState[] => {
    let count = 0;

    return (
      Children.map(children, (child, index) => {
        if (isValidElement<ContentBlockProps>(child)) {
          let previousContentBlock = Array.isArray(children) ? children[index - 1] : undefined;
          previousContentBlock = isValidElement<ContentBlockProps>(previousContentBlock)
            ? previousContentBlock
            : undefined;

          count += 1;

          return {
            complete:
              previousContentBlock && !previousContentBlock.props.complete
                ? false
                : child.props.complete ?? false,
            number: child.props.title ? child.props.number || count : undefined,
          };
        }
        return {};
      }) || []
    );
  };

  const [state, setState] = useState<ContentBlockState[]>(stateBuilder());

  const handleContinue = (index: number) => () => {
    setState(
      state.map((blockState, blockIndex) => {
        const previousBlockState = state[blockIndex - 1];

        if (blockIndex === index) {
          return {
            ...blockState,
            complete:
              previousBlockState && previousBlockState.complete !== undefined
                ? previousBlockState.complete
                : true,
          };
        }
        return blockState;
      })
    );
  };

  const handleEdit = (index: number) => () => {
    setState(
      state.map((blockState, blockIndex) => {
        if (blockIndex >= index) {
          return {
            ...blockState,
            complete: false,
          };
        }
        return blockState;
      })
    );
  };

  const childrenWithState = Children.map(children, (child, index) => {
    if (isValidElement<ContentBlockProps>(child)) {
      const previousContentBlock = state[index - 1];

      return cloneElement(child, {
        ...state[index],
        disabled:
          previousContentBlock && previousContentBlock.complete !== undefined
            ? !previousContentBlock.complete
            : false,
        onContinue: handleContinue(index),
        onEdit: handleEdit(index),
      });
    }

    return child;
  });

  return <Wrapper>{childrenWithState}</Wrapper>;
};
