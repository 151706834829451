import styled from "styled-components";

export const PaymentDetailsWrapper = styled.div`
  display: flex;
  position: relative;
  border: 1px solid ${({ theme }) => theme.newColors.background.variant4};
  border-bottom: none;

  &:first-child {
    border-radius: ${({ theme }) => `${theme.ratios.level1} ${theme.ratios.level1} 0 0`};
  }

  &:last-child {
    border-radius: ${({ theme }) => `0 0 ${theme.ratios.level1} ${theme.ratios.level1}`};
    border-bottom: 1px solid ${({ theme }) => theme.newColors.background.variant4};
  }

  & > *:first-child {
    flex: 1;
  }

  & label {
    margin: 0;

    &:first-child {
      margin: 0;
    }
  }
`;
