import styled from "styled-components";

export default styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.greyLight};
  border-bottom: 1px solid ${({ theme }) => theme.colors.greyLightest};
  transition: box-shadow 0.4s cubic-bezier(0.42, 0.0, 0.58, 1.0);
  
  .stuck & {
    box-shadow: 0px -1px 10px 1px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s cubic-bezier(0.42, 0.0, 0.58, 1.0);
  }

  &:before {
    content: "";
    position: absolute;
    background: ${({ theme }) => theme.colors.white};
    top: -20px;
    height: 17px;
    left: 0;
    right:0;
     
    @media (min-width: 768px) {
      display: none;
    }
  }
  
  @media (min-width: 768px) {
    border: none;,
    padding: clamp(1.3rem, 3.3vw, 1.5rem) clamp(1.5rem, 3.5vw, 1.7rem);
    transition: none;

    .stuck & {
      box-shadow: none;
    }
  }
`;

export const OrderSummaryHeader = styled.header`
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    padding: clamp(1.3rem, 3.3vw, 1.5rem) clamp(1.5rem, 3.5vw, 1.7rem);
  }
`;

export const OrderSummaryTitle = styled.h3`
  font-size: 1rem;
  margin: 0;
  padding: 0;

  @media (min-width: 768px) {
    font-size: 1.125rem;
  }
`;

export const OrderSummaryBtn = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  background: none;
  padding: clamp(1.3rem, 3.3vw, 1.5rem) clamp(1.5rem, 3.5vw, 1.7rem);
  color: ${({ theme }) => theme.colors.text};

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
`;

export const OrderSummaryContent = styled.div<{ isOpen: boolean }>`
  overflow: hidden;
  transition: ${({ isOpen }) =>
    isOpen
      ? "max-height 1s cubic-bezier(0, 1, 0.5, 1)"
      : "max-height 0.5s cubic-bezier(0, 1, 0.5, 1)"};
  max-height: ${({ isOpen }) => (isOpen ? "225px" : "0px")};

  @media (min-width: 768px) {
    transition: none;
  }

  & > div {
    padding: clamp(1rem, 3.1vw, 1.2rem) clamp(1.5rem, 3.5vw, 1.7rem) clamp(1.3rem, 3.3vw, 1.5rem);

    @media (min-width: 768px) {
      padding-top: 0;
    }
  }
`;

export const TotalValueRow = styled.tr`
  border-top: 1px solid ${({ theme }) => theme.colors.grey};

  th {
    padding-top: 1rem;
  }
`;
