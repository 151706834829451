import { FC, InputHTMLAttributes, ReactNode } from "react";
import {
  InputWrapper,
  Label,
  Input as InputElement,
  Radio,
  Checkbox,
  LabelText,
  ErrorMessage,
  DropdownArrow,
  SelectWrapper,
} from "./Input.style";

interface InputProps extends InputHTMLAttributes<HTMLInputElement | HTMLSelectElement> {
  id: string;
  label: ReactNode;
  small?: boolean;
  error?: string;
  isHiddenOnRecord?: boolean;
  "data-testid"?: string;
  hasMargin?: boolean;
  hasPadding?: boolean;
}

export const Input: FC<InputProps> = ({
  id,
  label,
  type = "text",
  small = false,
  children,
  error,
  isHiddenOnRecord = true,
  hasMargin = true,
  hasPadding = true,
  "data-testid": testId = id,
  ...inputProps
}) => (
  <InputWrapper hasMargin={hasMargin}>
    <Label htmlFor={id} type={type} error={!!error} hasPadding={hasPadding}>
      {["radio", "checkbox"].includes(type) && (
        <InputElement
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...inputProps}
          id={id}
          type={type}
          className={isHiddenOnRecord ? "fs-exclude" : ""}
          data-testid={`${testId}.input`}
        />
      )}
      {type === "radio" && (
        <Radio data-testid="customRadio" className={isHiddenOnRecord ? "fs-exclude" : ""} />
      )}
      {type === "checkbox" && (
        <Checkbox data-testid="customCheckbox" className={isHiddenOnRecord ? "fs-exclude" : ""} />
      )}
      <LabelText data-testid={`${testId}.label`}>
        {small ? <small>{label}</small> : label}
      </LabelText>
      {!["radio", "checkbox"].includes(type) &&
        (type === "select" ? (
          <SelectWrapper>
            <InputElement
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...inputProps}
              id={id}
              error={!!error}
              as="select"
              isSelect
              className={isHiddenOnRecord ? "fs-exclude" : ""}
              data-testid={`${testId}.input`}
            >
              {children}
            </InputElement>
            <DropdownArrow />
          </SelectWrapper>
        ) : (
          <InputElement
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...inputProps}
            id={id}
            type={type}
            error={!!error}
            className={isHiddenOnRecord ? "fs-exclude" : ""}
            data-testid={`${testId}.input`}
          />
        ))}
    </Label>
    {error && <ErrorMessage>{error}</ErrorMessage>}
  </InputWrapper>
);
