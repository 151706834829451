import { ChangeEvent, useState, useEffect, useMemo, useCallback } from "react";
import { isEnum } from "@utils/isEnum";
import {
  PaymentMethodTypes,
  isCard,
  isDirectDebit,
  isInvoice,
  PaymentMethods,
  PaymentMethod as BasePaymentMethod,
  InvoicePaymentMethod,
  CardPaymentMethod,
  DirectDebitPaymentMethod,
} from "@models/paymentMethods";
import { usePaymentMethod } from "@hooks/usePaymentMethod";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useTranslation } from "next-i18next";
import { useOrder } from "@hooks/useOrder";
import { SummaryInfo, SummaryTile } from "@containers/CompanyDetails/CompanyDetails.style";
import { Button } from "@components/Button";
import useSWR from "swr";
import { apiExtension } from "@components/PaymentMethods/apiExtension";
import { ErrorLike, fetcherWithToken } from "@utils/fetcher";
import { useOrderReference } from "@hooks/useOrderReference";
import * as Sentry from "@sentry/nextjs";
import { useRouter } from "next/router";
import { sendEvent } from "@utils/sendMessage";
import { useRedirect } from "@hooks/useRedirect";
import { Brand, useBrand } from "@hooks/useBrand";
import { PaymentDetails } from "@components/PaymentMethods/PaymentDetails";
import { PaymentMethod } from "@components/PaymentMethods/PaymentMethod";
import { useLocale } from "@hooks/useLocale";
import { captureException } from "@sentry/nextjs";
import { SupportMessage } from "@components/SupportMessage";
import {
  AddNewPaymentMethod,
  BackButtonWrapper,
  ContentWrapper,
} from "./PaymentAuthorisation.style";

export const fetcherMultipleWithToken =
  (token: string, init?: RequestInit) =>
  async (...urls: string[]): Promise<PaymentMethods<BasePaymentMethod>> => {
    const data = await Promise.all(
      urls.map((url) => fetcherWithToken<PaymentMethods<BasePaymentMethod>>(token, init)(url))
    );

    const bar = data.reduce<PaymentMethods<BasePaymentMethod>>(
      (acc, curr) => ({
        count: acc.count + curr.count,
        next: null,
        previous: null,
        results: [...acc.results, ...curr.results],
      }),
      {
        count: 0,
        next: null,
        previous: null,
        results: [],
      }
    );

    return bar;
  };

export const PaymentAuthorisationPrefetch = () => {
  const { data: orderData, paymentPlan, organisationId, isLoading } = useOrder();
  const { query } = useRouter();
  const { key } = useOrderReference();
  const { redirect } = useRedirect();
  const { brand } = useBrand();

  const paymentMethodOptions = useMemo(
    () =>
      !isLoading
        ? Array.from(
            new Set(
              (
                paymentPlan?.scheduled_payments[0]?.allowed_payment_methods || [
                  { type: PaymentMethodTypes.CARD },
                ]
              ).map(({ type }) => type)
            )
          ) || []
        : [],
    [isLoading, paymentPlan?.scheduled_payments]
  );

  useSWR<PaymentMethods<BasePaymentMethod>, ErrorLike>(
    () =>
      organisationId
        ? paymentMethodOptions.map(
            (paymentType) => `/organisations/${organisationId}/${apiExtension(paymentType)}`
          )
        : null,
    fetcherMultipleWithToken(key),
    {
      onError: async (err) => {
        // TODO: remove repeated error logic
        Sentry.captureException(err);

        try {
          await Sentry.flush(2000);
        } catch (sentryFlushErr) {
          console.error("Sentry failed to complete network requests");
        }

        if (query.embedded) {
          // TODO: provide failure reason
          sendEvent("failure");
        } else {
          // TODO: find a better way to handle this for telesales
          void redirect(
            brand === Brand.BOX ? "/error" : orderData?.payment_offer?.urls.failure || "/error"
          );
        }
      },
    }
  );

  return null;
};

export const PaymentAuthorisation = ({ onClick, isComplete }: ContentBlockChildProps) => {
  const { t } = useTranslation(["page-order", "common"]);
  const { data: orderData, paymentPlan, organisationId, isLoading } = useOrder();
  const { locale } = useLocale();
  const { key } = useOrderReference();
  const { query } = useRouter();
  const { redirect } = useRedirect();
  const { brand } = useBrand();
  const { paymentMethod, setPaymentMethod } = usePaymentMethod();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<BasePaymentMethod | null>(
    null
  );
  const [showAddNewPayment, setShowAddNewPayment] = useState(false);
  const [hasCheckedSavedPaymentMethods, setHasCheckedSavedPaymentMethod] = useState(false);

  const [selectedPaymentOption, setSelectedPaymentOption] = useState<PaymentMethodTypes | null>(
    null
  );

  const completePaymentSelection = useCallback(
    (savePaymentMethod?: BasePaymentMethod) => {
      setPaymentMethod(savePaymentMethod);
      setShowAddNewPayment(false);
      onClick?.();
    },
    [onClick, setPaymentMethod]
  );

  const handleAutoSelection = useCallback(
    (savePaymentMethod?: BasePaymentMethod) => {
      if (savePaymentMethod) {
        setSelectedPaymentMethod(savePaymentMethod);
        completePaymentSelection(savePaymentMethod);
      }
    },
    [completePaymentSelection]
  );

  // TODO: when multiple payment methods are available then the below logic will need to be change
  const paymentMethodOptions = useMemo(
    () =>
      !isLoading
        ? Array.from(
            new Set(
              (paymentPlan?.scheduled_payments[0]?.allowed_payment_methods.length
                ? paymentPlan.scheduled_payments[0].allowed_payment_methods
                : [{ type: PaymentMethodTypes.CARD }]
              ).map(({ type }) => type)
            )
          ) || []
        : [],
    [isLoading, paymentPlan?.scheduled_payments]
  );

  const {
    data: savedPaymentMethods,
    error: savedPaymentMethodsError,
    mutate: savedPaymentMethodsMutate,
  } = useSWR<PaymentMethods<BasePaymentMethod>, ErrorLike>(
    () =>
      organisationId
        ? paymentMethodOptions.map(
            (paymentType) => `/organisations/${organisationId}/${apiExtension(paymentType)}`
          )
        : null,
    fetcherMultipleWithToken(key),
    {
      onError: async (err) => {
        // TODO: remove repeated error logic
        Sentry.captureException(err);

        try {
          await Sentry.flush(2000);
        } catch (sentryFlushErr) {
          console.error("Sentry failed to complete network requests");
        }

        if (query.embedded) {
          // TODO: provide failure reason
          sendEvent("failure");
        } else {
          // TODO: find a better way to handle this for telesales
          void redirect(
            brand === Brand.BOX ? "/error" : orderData?.payment_offer?.urls.failure || "/error"
          );
        }
      },
    }
  );

  const handleFormSubmission = () => {
    if (selectedPaymentMethod) {
      completePaymentSelection(selectedPaymentMethod);
    }
  };

  const handlePaymentMethodSelection = (savePaymentMethod: BasePaymentMethod) => () => {
    setSelectedPaymentMethod(savePaymentMethod);
  };

  const setDefault = async (
    defaultPaymentMethod: BasePaymentMethod
  ): Promise<BasePaymentMethod[]> => {
    const removeDefaultPatch = async (currentDefaultPaymentMethod: BasePaymentMethod) => {
      if (organisationId) {
        await fetcherWithToken<BasePaymentMethod>(key, {
          method: "PATCH",
          body: JSON.stringify({
            is_default: false,
          }),
        })(
          `/organisations/${organisationId}/${apiExtension(currentDefaultPaymentMethod.type)}/${
            currentDefaultPaymentMethod.id
          }`
        )
          .then(() => {
            window?.analytics.track("Payment method set as default", {
              ...currentDefaultPaymentMethod,
              is_default: false,
            });
          })
          .catch((err) => {
            captureException(err);
          });
      } else {
        captureException(new Error("No organisation ID available"));
      }
    };

    const arrayMove = (arr: BasePaymentMethod[], fromIndex: number, toIndex: number) => {
      const element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    };

    if (savedPaymentMethods) {
      const currentDefaults: BasePaymentMethod[] = [];
      const results = savedPaymentMethods.results.map((savedPaymentMethod) => {
        if (savedPaymentMethod.is_default && defaultPaymentMethod.id !== savedPaymentMethod.id) {
          currentDefaults.push(savedPaymentMethod);
        }
        return {
          ...savedPaymentMethod,
          is_default: defaultPaymentMethod.id === savedPaymentMethod.id,
        };
      });

      arrayMove(
        results,
        results.findIndex(
          (savedPaymentMethod) => savedPaymentMethod.id === defaultPaymentMethod.id
        ),
        results.findIndex(
          (savedPaymentMethod) => savedPaymentMethod.type === defaultPaymentMethod.type
        )
      );

      await Promise.all(
        currentDefaults.map((currentDefault) => removeDefaultPatch(currentDefault))
      );

      return results;
    }

    return [];
  };

  const handleDefault = (defaultPaymentMethod: BasePaymentMethod) => async () => {
    if (savedPaymentMethods) {
      await savedPaymentMethodsMutate({
        ...savedPaymentMethods,
        results: await setDefault(defaultPaymentMethod),
      });
    }
  };

  const handleDelete = (savedPaymentMethod: BasePaymentMethod) => async () => {
    if (savedPaymentMethod.id === selectedPaymentMethod?.id) {
      setSelectedPaymentMethod(null);
    }

    // update data in background after update UI...
    if (savedPaymentMethods) {
      await savedPaymentMethodsMutate({
        ...savedPaymentMethods,
        count: savedPaymentMethods.count - 1,
        results: (savedPaymentMethods?.results || []).filter(
          ({ id }) => id !== savedPaymentMethod.id
        ),
      });
    } else {
      await savedPaymentMethodsMutate();
    }
  };

  const handleAddNewPaymentMethod = async (
    newPaymentMethod: CardPaymentMethod | DirectDebitPaymentMethod
  ) => {
    handleAutoSelection(newPaymentMethod);
    setSelectedPaymentOption(null);

    // update data in background after update UI...
    if (savedPaymentMethods) {
      await savedPaymentMethodsMutate({
        ...savedPaymentMethods,
        count: savedPaymentMethods.count + 1,
        results: [
          ...(newPaymentMethod.is_default
            ? await setDefault(newPaymentMethod)
            : savedPaymentMethods.results),
          newPaymentMethod,
        ],
      });
    } else {
      await savedPaymentMethodsMutate({
        count: 1,
        previous: null,
        next: null,
        results: [newPaymentMethod],
      });
    }
  };

  const handleSelectedPaymentOptionChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    if (isEnum(PaymentMethodTypes)(value)) {
      setSelectedPaymentOption(value);
      if (value === PaymentMethodTypes.INVOICE) {
        setSelectedPaymentMethod(
          savedPaymentMethods?.results.find((savedPaymentMethod) =>
            isInvoice(savedPaymentMethod)
          ) || null
        );
      } else {
        setSelectedPaymentMethod(null);
      }
    } else {
      setSelectedPaymentOption(null);
    }
  };

  const handleBackButton = () => {
    setShowAddNewPayment(false);
    setSelectedPaymentOption(null);
  };

  // As we need to create an invoice on init...
  useEffect(() => {
    // If pay by invoice is available and there are no saved invoices, then create one...
    if (
      paymentMethodOptions.includes(PaymentMethodTypes.INVOICE) &&
      savedPaymentMethods &&
      !savedPaymentMethodsError &&
      !savedPaymentMethods.results.find((savedPaymentMethod) => isInvoice(savedPaymentMethod))
    ) {
      void (async () => {
        if (organisationId) {
          const invoice = await fetcherWithToken<InvoicePaymentMethod>(key, {
            method: "POST",
            headers: new Headers({
              "content-type": "application/json",
              authorization: `OrderKey ${key}`,
            }),
            body: JSON.stringify({ type: "invoice" }),
          })(`/organisations/${organisationId}/${apiExtension(PaymentMethodTypes.INVOICE)}`);

          await savedPaymentMethodsMutate({
            ...savedPaymentMethods,
            count: savedPaymentMethods.count + 1,
            results: [...savedPaymentMethods.results, invoice],
          });
        }
      })();
    }
  }, [
    key,
    organisationId,
    paymentMethodOptions,
    savedPaymentMethods,
    savedPaymentMethodsError,
    savedPaymentMethodsMutate,
  ]);

  useEffect(() => {
    if (savedPaymentMethods && !hasCheckedSavedPaymentMethods) {
      if (
        paymentMethod &&
        savedPaymentMethods.results.find(
          (savedPaymentMethod) => savedPaymentMethod.id === paymentMethod.id
        )
      ) {
        // if there is a previously selected saved payment, then select it
        handleAutoSelection(paymentMethod);
      } else {
        // if there is a default then select it
        handleAutoSelection(
          savedPaymentMethods.results.find((savedPaymentMethod) => savedPaymentMethod.is_default)
        );

        // if only payment is invoice then select it

        if (
          paymentMethodOptions.length === 1 &&
          paymentMethodOptions[0] === PaymentMethodTypes.INVOICE &&
          savedPaymentMethods.results.length === 1 &&
          isInvoice(savedPaymentMethods.results[0])
        ) {
          handleAutoSelection(savedPaymentMethods.results[0]);
        }
      }

      setHasCheckedSavedPaymentMethod(true);
    }
  }, [
    handleAutoSelection,
    hasCheckedSavedPaymentMethods,
    paymentMethod,
    paymentMethodOptions,
    savedPaymentMethods,
  ]);

  if (savedPaymentMethodsError) {
    return <SupportMessage error />;
  }

  if (isComplete && hasCheckedSavedPaymentMethods) {
    if (savedPaymentMethods && !savedPaymentMethodsError) {
      return (
        <div>
          {isCard(paymentMethod) && (
            <>
              <SummaryTile>
                {t("page-order:paymentAuthorisation.paymentSelected.debitCreditCard.title")}{" "}
                <small>{paymentMethod.is_default ? `(${t("common:default")})` : ""}</small>
              </SummaryTile>
              <SummaryInfo>
                <span style={{ textTransform: "capitalize" }}>{paymentMethod.card.brand}</span> ****
                {paymentMethod.card.last4}
              </SummaryInfo>
              <SummaryInfo>
                {t("page-order:paymentAuthorisation.card.expires")}:{" "}
                {new Date(
                  paymentMethod.card.exp_year,
                  paymentMethod.card.exp_month
                ).toLocaleDateString(locale, {
                  year: "numeric",
                  month: "2-digit",
                })}
              </SummaryInfo>
            </>
          )}

          {isDirectDebit(paymentMethod) && (
            <>
              <SummaryTile>
                {t("page-order:paymentAuthorisation.paymentSelected.direct_debit.title")}{" "}
                <small>{paymentMethod.is_default ? `(${t("common:default")})` : ""}</small>
              </SummaryTile>
              <SummaryInfo>
                <span style={{ textTransform: "capitalize" }}>
                  {paymentMethod.direct_debit.account_holder_name.toLocaleLowerCase()}
                </span>
              </SummaryInfo>
              <SummaryInfo>
                <span style={{ textTransform: "capitalize" }}>
                  {paymentMethod.direct_debit.bank_name.toLocaleLowerCase()}
                </span>{" "}
                ****{paymentMethod.direct_debit.account_number_ending}
              </SummaryInfo>
            </>
          )}

          {isInvoice(paymentMethod) &&
            (orderData && !isLoading ? (
              <>
                <SummaryTile>
                  {t("page-order:paymentAuthorisation.invoice.title")}{" "}
                  <small>{paymentMethod.is_default ? `(${t("common:default")})` : ""}</small>
                </SummaryTile>
                <SummaryInfo>
                  {t("page-order:paymentAuthorisation.invoice.labels.email")}:{" "}
                  {orderData.customer.user.email}
                </SummaryInfo>
                <SummaryInfo>
                  {t("page-order:paymentAuthorisation.invoice.labels.address")}:{" "}
                  {Object.values(orderData.customer.invoice_address).filter(Boolean).join(", ")}
                </SummaryInfo>
              </>
            ) : (
              <>
                <SummaryTile>
                  {t("page-order:paymentAuthorisation.invoice.title")}{" "}
                  {paymentMethod.is_default ? `(${t("common:default")})` : ""}
                </SummaryTile>
                <SummaryInfo>
                  <Skeleton width={100} />
                </SummaryInfo>
                <SummaryInfo>
                  <Skeleton width={175} />
                </SummaryInfo>
              </>
            ))}

          {!paymentMethod && <SupportMessage error />}
        </div>
      );
    }

    return (
      <div>
        <SummaryTile>
          <Skeleton width={280} />
        </SummaryTile>
        <SummaryInfo>
          <Skeleton width={100} />
        </SummaryInfo>
        <SummaryInfo>
          <Skeleton width={175} />
        </SummaryInfo>
      </div>
    );
  }

  const isOnlyInvoice =
    savedPaymentMethods &&
    savedPaymentMethods.results.length === 1 &&
    isInvoice(savedPaymentMethods.results[0]);

  if (savedPaymentMethods && !savedPaymentMethodsError && hasCheckedSavedPaymentMethods) {
    return (
      <div>
        {savedPaymentMethods.results.length && !isOnlyInvoice ? (
          <div>
            {showAddNewPayment ? (
              <ContentWrapper noGap>
                <div>
                  <BackButtonWrapper>
                    <Button type="button" variant="text" onClick={handleBackButton}>
                      &#60; {t("common:back")}
                    </Button>
                  </BackButtonWrapper>
                </div>
                <div>
                  {paymentMethodOptions.map(
                    (paymentType) =>
                      paymentType !== PaymentMethodTypes.INVOICE && (
                        <PaymentMethod
                          key={`paymentAuthorisation_${paymentType}`}
                          paymentType={paymentType}
                          isRadio
                          isChecked={selectedPaymentOption === paymentType}
                          onChange={handleSelectedPaymentOptionChange}
                          onNewPaymentMethod={handleAddNewPaymentMethod}
                        />
                      )
                  )}
                </div>
              </ContentWrapper>
            ) : (
              <ContentWrapper>
                <div>
                  {savedPaymentMethods.results.map((savedPaymentMethod) => (
                    <PaymentDetails
                      key={savedPaymentMethod.id}
                      paymentMethod={savedPaymentMethod}
                      isChecked={selectedPaymentMethod?.id === savedPaymentMethod.id}
                      onChange={handlePaymentMethodSelection(savedPaymentMethod)}
                      onDefault={handleDefault(savedPaymentMethod)}
                      onDelete={handleDelete(savedPaymentMethod)}
                    />
                  ))}
                </div>
                <AddNewPaymentMethod>
                  <Button type="button" variant="text" onClick={() => setShowAddNewPayment(true)}>
                    &#43;&nbsp;
                    <span>{t("page-order:paymentAuthorisation.addNewPaymentMethod")}</span>
                  </Button>
                </AddNewPaymentMethod>
                <Button
                  type="button"
                  onClick={handleFormSubmission}
                  disabled={!selectedPaymentMethod}
                >
                  {t("common:continue")}
                </Button>
              </ContentWrapper>
            )}
          </div>
        ) : (
          <ContentWrapper>
            <div>
              {paymentMethodOptions.map((paymentType) => (
                <PaymentMethod
                  key={`paymentAuthorisation_${paymentType}`}
                  paymentType={paymentType}
                  isRadio
                  isChecked={selectedPaymentOption === paymentType}
                  onChange={handleSelectedPaymentOptionChange}
                  onNewPaymentMethod={handleAddNewPaymentMethod}
                />
              ))}
            </div>
            {selectedPaymentMethod && (
              <Button type="button" onClick={handleFormSubmission}>
                {t("common:continue")}
              </Button>
            )}
          </ContentWrapper>
        )}
      </div>
    );
  }

  return (
    <div style={{ paddingLeft: "calc(24px + 0.75rem)" }}>
      <SummaryTile>
        <Skeleton width={280} />
      </SummaryTile>
      <SummaryInfo>
        <Skeleton width={100} />
      </SummaryInfo>
      <SummaryInfo>
        <Skeleton width={175} />
      </SummaryInfo>
    </div>
  );
};
