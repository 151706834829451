import styled from "styled-components";

export default styled.p`
  text-align: center;
  padding-bottom: ${({ theme }) => theme.ratios.level2};
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.newColors.text.variant4};

  &:not([href]) {
    cursor: not-allowed;
  }
`;
