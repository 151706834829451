import { FC, MouseEvent, useCallback, useEffect } from "react";
import { useTranslation } from "next-i18next";
import { ModalPortal } from "@components/ModalPortal";
import { Button } from "@components/Button";
import { ButtonsWrapper, Modal, ModalWrapper, ModelCloseButton } from "./Dialog.style";

type DialogProps =
  | {
      isOpen: boolean;
      onClose: () => void;
      onSubmit: () => void;
      cancelButtonText?: string;
      submitButtonText: string;
      hideActionButtons?: false;
    }
  | {
      isOpen: boolean;
      onClose: () => void;
      onSubmit?: never;
      cancelButtonText?: never;
      submitButtonText?: never;
      hideActionButtons: true;
    };

export const Dialog: FC<DialogProps> = ({
  children,
  isOpen,
  onClose,
  onSubmit,
  cancelButtonText,
  submitButtonText,
  hideActionButtons,
}) => {
  const { t } = useTranslation(["common"]);

  const escFunction = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const handleClose = () => {
    onClose();
  };

  const stopPropagation = (event: MouseEvent) => event.stopPropagation();

  if (isOpen) {
    return (
      <ModalPortal>
        <ModalWrapper>
          <Modal role="dialog" aria-modal="true" onClick={stopPropagation}>
            <div>{children}</div>

            {!hideActionButtons && (
              <ButtonsWrapper>
                <Button variant="secondary" type="button" onClick={handleClose}>
                  {cancelButtonText || t("cancel")}
                </Button>
                <Button type="button" variant="warning" onClick={onSubmit}>
                  {submitButtonText}
                </Button>
              </ButtonsWrapper>
            )}

            <ModelCloseButton type="button" aria-label="Close Dialog" onClick={handleClose}>
              <span>&#215;</span>
            </ModelCloseButton>
          </Modal>
        </ModalWrapper>
      </ModalPortal>
    );
  }

  return null;
};
