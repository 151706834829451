import { Input } from "@components/Input";
import { Timeline } from "@components/Timeline";
import { useBrand } from "@hooks/useBrand";
import { useLocale } from "@hooks/useLocale";
import { useOrder } from "@hooks/useOrder";
import { useOrderReference } from "@hooks/useOrderReference";
import { PaymentPlan } from "@models/paymentPlans";
import { currencyFormatter } from "@utils/currencyFormat";
import { parseQuery } from "@utils/parseQuery";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { ChangeEvent, ReactElement, useCallback, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  PaymentOfferWrapper,
  SummaryTile,
  SummaryInfo,
  SmallText,
  TimelineWrapper,
  ContinueButton,
} from "./PaymentOfferSelection.style";

export const PaymentOfferSelection = ({
  onClick,
  isComplete,
}: ContentBlockChildProps): ReactElement => {
  const { t } = useTranslation(["page-order", "common", "component-usp"]);
  const { brand } = useBrand();
  const { locale } = useLocale();
  const { pathname, query, replace } = useRouter();
  const { plan } = useOrderReference();
  const { data, paymentPlans, isLoading } = useOrder();
  const [selectedPaymentPlan, setSelectedPaymentPlan] = useState<PaymentPlan | undefined>(
    plan && paymentPlans ? paymentPlans.find(({ id }) => id === parseQuery(plan)) : undefined
  );
  const [hasCheckedPaymentOffers, setHasCheckedPaymentOffers] = useState(false);

  const paymentDate = selectedPaymentPlan?.scheduled_payments.length
    ? selectedPaymentPlan.scheduled_payments[selectedPaymentPlan.scheduled_payments.length - 1]
        ?.date
    : undefined;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    void replace(
      {
        pathname,
        query: { ...query, plan: event.target.value, showPaymentOffers: true },
      },
      {
        pathname,
        query: { ...query, plan: event.target.value, showPaymentOffers: true },
      },
      {
        shallow: true,
      }
    );
  };

  const wording = useCallback(
    (paymentPlan: PaymentPlan): { name: string } => {
      if (paymentPlan.name.match(/Pay in (\d+)x/g) && paymentPlan.scheduled_payments.length) {
        const instalments = paymentPlan.scheduled_payments.length;
        const ns = `paymentOfferSelection.plans.instalments${instalments > 1 ? "_plural" : ""}`;

        return {
          name: t(`${ns}.name`, {
            context: brand,
            instalments,
            returnObjects: true,
          }),
        };
      }

      if (paymentPlan.name.match(/(\d+)dEOM/g)) {
        const days = parseInt(paymentPlan.name.split("d")[0], 10);
        const ns = `paymentOfferSelection.plans.payLaterEndOfMonth${days > 1 ? "_plural" : ""}`;

        return {
          name: t(`${ns}.name`, {
            context: brand,
            days,
            returnObjects: true,
          }),
        };
      }

      if (paymentPlan.name.match(/(\d+)d/g)) {
        const days = parseInt(paymentPlan.name.split("d")[0], 10);
        const ns = `paymentOfferSelection.plans.payLater${days > 1 ? "_plural" : ""}`;

        return {
          name: t(`${ns}.name`, {
            context: brand,
            days,
            returnObjects: true,
          }),
        };
      }

      return {
        name: paymentPlan.name,
      };
    },
    [brand, t]
  );

  useEffect(() => {
    if (plan && paymentPlans) {
      setSelectedPaymentPlan(paymentPlans.find(({ id }) => id === plan));
    }
  }, [paymentPlans, plan]);

  useEffect(() => {
    if (paymentPlans && !hasCheckedPaymentOffers) {
      if (paymentPlans?.length === 1) {
        setSelectedPaymentPlan(paymentPlans[0]);
        onClick?.();
      }

      setHasCheckedPaymentOffers(true);
    }
  }, [onClick, paymentPlans, hasCheckedPaymentOffers]);

  // Has loaded order
  if (data && !isLoading) {
    // If we should display the payment offer radio list
    if (!isComplete && query.showPaymentOffers && paymentPlans && paymentPlans.length > 1) {
      return (
        <div>
          <div>
            {paymentPlans.map((paymentOffer) => (
              <PaymentOfferWrapper key={paymentOffer.id}>
                <Input
                  id={paymentOffer.id}
                  name="paymentOffers"
                  type="radio"
                  value={paymentOffer.id}
                  onChange={handleChange}
                  checked={parseQuery(plan) === paymentOffer.id}
                  label={
                    <>
                      {wording(paymentOffer).name}{" "}
                      <SmallText>({t("component-usp:noFeeNoInterest.title")})</SmallText>
                    </>
                  }
                  data-testid={`paymentOffer.${paymentOffer.name}`}
                  hasMargin={false}
                />
                {paymentOffer.id === selectedPaymentPlan?.id && (
                  <TimelineWrapper>
                    <Timeline />
                  </TimelineWrapper>
                )}
              </PaymentOfferWrapper>
            ))}
          </div>
          {onClick && (
            <ContinueButton type="button" onClick={onClick} disabled={!selectedPaymentPlan}>
              {t("common:continue")}
            </ContinueButton>
          )}
        </div>
      );
    }

    // If we should NOT show payment offer radio list or there is only one offer, aka show summary
    if (paymentPlans && (paymentPlans?.length === 1 || selectedPaymentPlan)) {
      return (
        <div>
          <SummaryTile>
            <strong>
              {
                wording(
                  paymentPlans.length === 1
                    ? paymentPlans[0]
                    : selectedPaymentPlan || paymentPlans[0]
                ).name
              }
            </strong>
          </SummaryTile>
          <SummaryInfo>
            {t("page-order:paymentOfferSelection.dueOn", {
              amount: currencyFormatter(locale, data.total_amount, data.currency),
              date: paymentDate && new Date(paymentDate).toLocaleDateString(locale),
            })}
          </SummaryInfo>
        </div>
      );
    }
  }

  // Loading payment offer radio list
  if (!isComplete && query.showPaymentOffers) {
    return (
      <div>
        <PaymentOfferWrapper>
          <div style={{ padding: "1rem" }}>
            <Skeleton style={{ width: "100%" }} />
          </div>
          <TimelineWrapper>
            <Timeline />
          </TimelineWrapper>
        </PaymentOfferWrapper>
        <PaymentOfferWrapper>
          <div style={{ padding: "1rem" }}>
            <Skeleton style={{ width: "100%" }} />
          </div>
        </PaymentOfferWrapper>
      </div>
    );
  }

  // Loading summary
  return (
    <div>
      <SummaryTile>
        <Skeleton width={110} />
      </SummaryTile>
      <SummaryInfo>
        <Skeleton width={220} />
      </SummaryInfo>
    </div>
  );
};
