import { useRef, useState } from "react";
import type { PaymentMethod as BasePaymentMethod } from "@models/paymentMethods";
import { useOnClickOutside } from "@hooks/useClickOutside";
import { DefaultPaymentMethod } from "./DefaultPaymentMethod";
import { DeletePaymentMethod } from "./DeletePaymentMethod";
import { MoreOptionsButton, MoreOptionsMenu, Wrapper } from "./MoreOptions.style";

type MoreOptionsProps<PaymentMethod> = {
  paymentMethod: PaymentMethod;
  onDefault: (paymentMethod: PaymentMethod) => void;
  onDelete: (paymentMethod: PaymentMethod) => void;
};

export const MoreOptions = <PaymentMethod extends BasePaymentMethod>({
  paymentMethod,
  onDefault,
  onDelete,
}: MoreOptionsProps<PaymentMethod>) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    setIsOpen(false);
  };

  useOnClickOutside(ref, handleClose);

  const handleOnOptionClick = () => {
    setIsOpen(!isOpen);
  };

  // TODO: better keyboard navigation

  return (
    <Wrapper ref={ref}>
      <MoreOptionsButton
        type="button"
        onClick={handleOnOptionClick}
        aria-haspopup="true"
        aria-expanded={isOpen}
        aria-label="More Options"
      />
      <MoreOptionsMenu isOpen={isOpen} role="menu">
        {!paymentMethod.is_default && (
          <DefaultPaymentMethod
            paymentMethod={paymentMethod}
            onSubmit={onDefault}
            onOptionClick={handleClose}
          />
        )}
        <DeletePaymentMethod
          paymentMethod={paymentMethod}
          onSubmit={onDelete}
          onOptionClick={handleClose}
        />
      </MoreOptionsMenu>
    </Wrapper>
  );
};
