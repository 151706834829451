import styled, { css } from "styled-components";

export default styled.div<{ singleColumn: boolean }>`
  display: grid;
  padding-bottom: clamp(1.3rem, 3.3vw, 1.5rem);
  grid-template-areas:
    "aside"
    "introText"
    "main";
  ${({ singleColumn }) =>
    !singleColumn &&
    css`
      grid-gap: calc(clamp(1.3rem, 3.3vw, 1.5rem) / 2);
    `};

  @media (min-width: 768px) {
    padding: clamp(1.3rem, 3.3vw, 1.5rem);
    padding-top: ${({ singleColumn }) =>
      singleColumn ? "calc(clamp(1.3rem, 3.3vw, 1.5rem) / 2)" : 0};
    ${({ singleColumn }) =>
      !singleColumn &&
      css`
        grid-template-areas:
          "introText introText"
          "main aside";
        grid-template-columns: 1fr 42%;
        grid-gap: clamp(1.3rem, 3.3vw, 1.5rem);
      `}
  }

  ${({ singleColumn }) =>
    !singleColumn &&
    css`
      @media (min-width: 1022px) {
        grid-template-columns: 1fr 402px;
      }
    `}
`;

export const Intro = styled.p`
  font-size: ${({ theme }) => theme.typography.small.fontSize};
  color: ${({ theme }) => theme.colors.textLight};
  grid-area: introText;
  margin: 0;
  padding: 0 clamp(1.3rem, 3.3vw, 1.5rem);

  @media (min-width: 768px) {
    padding: 0;
  }
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  gap: clamp(1.3rem, 3.3vw, 1.5rem);
  grid-area: main;
`;

export const Aside = styled.aside`
  grid-area: aside;
  z-index: 997;
  position: sticky;
  top: -1px;
  padding: 1px;

  @media (min-width: 768px) {
    position: relative;
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: clamp(1.3rem, 3.3vw, 1.5rem);

    @media (min-width: 768px) {
      position: sticky;
      top: clamp(1.3rem, 3.3vw, 1.5rem);
    }
  }
`;

export const UspMobile = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

export const UspDesktop = styled(UspMobile)`
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`;
