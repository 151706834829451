import styled from "styled-components";

export default styled.div`
  border: 1px solid ${({ theme }) => theme.newColors.background.variant4};
  border-bottom: none;

  &:first-child {
    border-radius: ${({ theme }) => `${theme.ratios.level1} ${theme.ratios.level1} 0 0`};
  }

  &:last-child {
    border-radius: ${({ theme }) => `0 0 ${theme.ratios.level1} ${theme.ratios.level1}`};
    border-bottom: 1px solid ${({ theme }) => theme.newColors.background.variant4};
  }
`;

export const ContentWrapper = styled.div`
  padding: clamp(0.5rem, 3.5vw, 1.25rem);
  background: ${({ theme }) => theme.newColors.background.variant1};
`;

export const Label = styled.span`
  display: flex;
  align-items: center;
`;

export const Title = styled.h6`
  display: flex;
  padding: 0.75rem 0;
  margin: 0;
`;

export const SubTitle = styled.h4`
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
`;

export const ContactDetails = styled.dl`
  grid-template-columns: auto;
  grid-row-gap: 0;
  margin-top: clamp(1.3rem, 3.3vw, 1.5rem);

  dd {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: 550px) {
    grid-template-columns: max-content auto;
  }

  @media (min-width: 768px) {
    grid-template-columns: auto;
  }

  @media (min-width: 1050px) {
    grid-template-columns: max-content auto;
  }
`;

export const FormButtonWrapper = styled.div`
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  margin-top: 1.5rem;
`;
