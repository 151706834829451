import { ReactElement, useState } from "react";
import {
  CardPaymentMethod,
  DirectDebitPaymentMethod,
  PaymentMethodTypes,
} from "@models/paymentMethods";
import { fetcherWithToken } from "@utils/fetcher";
import { useOrderReference } from "@hooks/useOrderReference";
import { useOrder } from "@hooks/useOrder";
import { GoCardlessDropinOnSuccess } from "@gocardless/react-dropin";
import { useTranslation } from "next-i18next";
import { useBrand } from "@hooks/useBrand";
import { CardForm } from "@components/PaymentMethods/Card";
import { DirectDebitForm } from "@components/PaymentMethods/DirectDebit";
import { Dialog } from "@components/Dialog";
import { Button } from "@components/Button";
import Wrapper, { ModalWrapper, Loader } from "./NewPaymentMethod.style";

type BasePaymentMethod = CardPaymentMethod | DirectDebitPaymentMethod;

type NewPaymentMethodProps = {
  paymentType: PaymentMethodTypes;
  onSuccess: (paymentMethod: BasePaymentMethod) => void;
  isInline?: boolean;
};

export const NewPaymentMethod = ({
  paymentType,
  onSuccess,
  isInline,
}: NewPaymentMethodProps): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(isInline || false);
  const { organisationId, isLoading } = useOrder();
  const { key } = useOrderReference();
  const { t } = useTranslation(["page-order"]);
  const { brand } = useBrand();

  const handleAddPaymentMethod = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleCardOnSuccess = (paymentMethod: CardPaymentMethod) => {
    // ** NOTE: We don't actually care about the paymentMethod type here so can Type Assert ** //
    onSuccess(paymentMethod as BasePaymentMethod);
    handleClose();
  };

  const handleDirectDebitSuccess: GoCardlessDropinOnSuccess = async ({ id: billingRequestId }) => {
    try {
      if (organisationId && billingRequestId) {
        const paymentMethod = await fetcherWithToken<DirectDebitPaymentMethod>(key, {
          method: "POST",
          body: JSON.stringify({
            billing_request_id: billingRequestId,
            is_default: true,
          }),
        })(`/organisations/${organisationId}/gocardless_payment_methods`);

        // ** NOTE: We don't actually care about the paymentMethod type here so can Type Assert ** //
        onSuccess(paymentMethod as BasePaymentMethod);

        handleClose();
      }
    } catch (err) {
      console.error(err);
      // setGoCardlessError(err);
      handleClose();
    }
  };

  if (isInline && paymentType === PaymentMethodTypes.CARD) {
    return <CardForm onSuccess={handleCardOnSuccess} />;
  }

  return (
    <Wrapper>
      <Button
        type="button"
        onClick={handleAddPaymentMethod}
        data-testid="paymentSelection.addPaymentMethod"
      >
        {t(`paymentAuthorisation.${paymentType}.addNewPaymentButtonText`, {
          context: brand,
        })}
      </Button>

      {paymentType === PaymentMethodTypes.CARD && (
        <Dialog isOpen={isOpen} onClose={handleClose} hideActionButtons>
          <h3>{t("paymentAuthorisation.card.form.title", { context: brand })}</h3>
          <p>
            {t("paymentAuthorisation.card.form.supportingText", {
              context: brand,
            })}
          </p>
          <CardForm onSuccess={handleCardOnSuccess} />
        </Dialog>
      )}

      {paymentType === PaymentMethodTypes.DIRECT_DEBIT && isOpen && (
        <ModalWrapper>
          {isLoading ? (
            <Loader />
          ) : (
            <DirectDebitForm onSuccess={handleDirectDebitSuccess} onExit={handleClose} />
          )}
        </ModalWrapper>
      )}
    </Wrapper>
  );
};
