import { ReactElement } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useTranslation } from "next-i18next";
import { useOrder } from "@hooks/useOrder";
import { SummaryInfo, SummaryTile } from "./CompanyDetails.style";

export const CompanyDetails = (): ReactElement => {
  const { data, isLoading } = useOrder();
  const { t } = useTranslation(["page-order"]);

  return (
    <div data-testid="companyDetails">
      {data && !isLoading ? (
        <div>
          <SummaryTile>{t("companyDetails.labels.companyInformation")}</SummaryTile>
          <SummaryInfo>{data?.customer.organisation.company.name}</SummaryInfo>
        </div>
      ) : (
        <div>
          <SummaryTile>
            <Skeleton width={115} />
          </SummaryTile>
          <SummaryInfo>
            <Skeleton width={115} />
          </SummaryInfo>
          <SummaryInfo>
            <Skeleton width={115} />
          </SummaryInfo>
        </div>
      )}
    </div>
  );
};
