import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div<{ large?: boolean }>`
  margin-left: 0.5rem;

  & > * {
    vertical-align: middle;
  }

  &:first-of-type {
    margin-left: 1rem;
  }
`;
