import styled from "styled-components";

export const PaymentInfoWrapper = styled.div`
  display: flex;
  padding-left: clamp(0rem, 1vw, 0.25rem);
`;

export const PaymentInfoLeft = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  min-width: 88px;
  align-self: center;

  p {
    line-height: 1.1;
    margin: 0 0 ${({ theme }) => theme.ratios.level1};

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const PaymentInfoRight = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  align-self: center;
  margin-left: auto;
  padding-left: 1rem;

  p {
    margin: 0;
  }
`;

export const PaymentInfoInnerWrapper = styled.div<{ large?: boolean }>`
  display: flex;
  align-items: center;

  & > * {
    margin-left: 0.5rem;

    &:first-child {
      margin-left: 0;
    }
  }
`;

export const DefaultText = styled.small`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 75%;
`;
