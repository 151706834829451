/* eslint-disable react/jsx-props-no-spreading */
import type { ButtonHTMLAttributes, ReactNode } from "react";
import { PrimaryButton, SecondaryButton, TextButton, WarningButton } from "./Button.styled";

type ButtonProps = {
  children?: ReactNode;
  variant?: "primary" | "secondary" | "tertiary" | "warning" | "text"; // future options
  type: ButtonHTMLAttributes<HTMLButtonElement>["type"];
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = ({ children, variant, ...attrs }: ButtonProps) => {
  if (variant === "secondary") {
    return <SecondaryButton {...attrs}>{children}</SecondaryButton>;
  }

  if (variant === "text") {
    return <TextButton {...attrs}>{children}</TextButton>;
  }

  if (variant === "warning") {
    return <WarningButton {...attrs}>{children}</WarningButton>;
  }

  // variant === "primary"
  return <PrimaryButton {...attrs}>{children}</PrimaryButton>;
};
