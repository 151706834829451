import styled from "styled-components";

export default styled.section`
  display: flex;
  flex-direction: column;
  background: white;
  padding: clamp(1.3rem, 3.3vw, 1.5rem) clamp(1.5rem, 3.5vw, 1.7rem);
  gap: 1rem;
`;

export const UspArticle = styled.article`
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: center;
  justify-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`;

export const UspTitle = styled.h3`
  font-size: ${({ theme }) => theme.typography.small.fontSize};
  padding: 0 0 0.25rem 0;
  margin: 0;
`;

export const UspSentence = styled.p`
  font-size: ${({ theme }) => theme.typography.small.fontSize};
  color: ${({ theme }) => theme.colors.textLight};

  a {
    text-decoration: none;
    font-weight: normal;

    &:hover {
      text-decoration: underline;
    }
  }
`;
