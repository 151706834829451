import { useBrand } from "@hooks/useBrand";
import { CardBrands } from "@models/card";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { cardIconSizes } from "./CardIconsSizes";
import { IconWrapper, Wrapper } from "./CardTitle.style";

export const CardTitle = () => {
  const { t } = useTranslation(["page-order"]);
  const { brand } = useBrand();

  return (
    <Wrapper>
      <span>
        {t("paymentAuthorisation.paymentMethods.labels.debitCreditCard", { context: brand })}
      </span>
      {[CardBrands.VISA, CardBrands.MASTERCARD, CardBrands.AMERICAN_EXPRESS].map(
        (cardBrand) =>
          cardBrand !== CardBrands.UNKNOWN && (
            <IconWrapper key={cardBrand}>
              <Image
                src={`/icons/cardBrands/${cardBrand.toLocaleLowerCase().replace(/\s/, "-")}.svg`}
                alt={cardBrand}
                width={cardIconSizes[cardBrand]}
                height={16}
              />
            </IconWrapper>
          )
      )}
    </Wrapper>
  );
};
