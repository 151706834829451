import { useBrand } from "@hooks/useBrand";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { IconWrapper, Wrapper } from "./DirectDebitTitle.style";

export const DirectDebitTitle = () => {
  const { t } = useTranslation(["page-order"]);
  const { brand } = useBrand();

  return (
    <Wrapper>
      <span>
        {t("paymentAuthorisation.paymentMethods.labels.direct_debit", { context: brand })}
      </span>
      <IconWrapper>
        <Image
          src="/icons/direct-debit.svg"
          alt={t("paymentAuthorisation.paymentMethods.labels.direct_debit", {
            context: brand,
          })}
          width={68.33}
          height={22.39}
        />
      </IconWrapper>
    </Wrapper>
  );
};
