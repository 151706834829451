import { Button } from "@components/Button";
import styled from "styled-components";

export const PaymentOfferWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-bottom: none;
  padding-bottom: 1px;

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
    padding-bottom: none;
  }
`;

export const TimelineWrapper = styled.div`
  background: ${({ theme }) => theme.colors.greyLightest};
  padding: ${({ theme }) => theme.ratios.level4};
`;

export const SmallText = styled.small`
  color: ${({ theme }) => theme.colors.textLight};
`;

export const SummaryTile = styled.h3`
  font-size: 1rem;
  margin: 0 0 ${({ theme }) => theme.ratios.level1};
  padding: 0;
`;

export const SummaryInfo = styled.p`
  margin: 0 0 ${({ theme }) => theme.ratios.level1};
  padding: 0;
  text-transform: "uppercase";
`;

export const ContinueButton = styled(Button)`
  margin-top: clamp(1.3rem, 3.3vw, 1.5rem);
  width: 100%;
`;
