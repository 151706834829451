import { useEffect } from "react";
import { GetServerSideProps, NextPage } from "next";
import { useOrder } from "@hooks/useOrder";
import { useBrand } from "@hooks/useBrand";
import {
  PaymentAuthorisation,
  PaymentAuthorisationPrefetch,
} from "@containers/PaymentAuthorisation";
import { PaymentConfirmation } from "@containers/PaymentConfirmation";
import { brandServerSideProps } from "@utils/brandServerSideProps";
import { PaymentOfferSelection } from "@containers/PaymentOfferSelection";
import { CompanyDetails } from "@containers/CompanyDetails";
import { ContentBlock, ContentBlockWrapper } from "@components/layouts/ContentBlock";
import { WithSupportingContent } from "@components/layouts/WithSupportingContent";
import { useRouter } from "next/router";
import { isInvoice } from "@models/paymentMethods";
import { useTranslation } from "next-i18next";

const HomePage: NextPage = () => {
  const { data, paymentPlan, paymentPlans } = useOrder();
  const {
    query: { showPaymentOffers, embedded },
  } = useRouter();
  const { brand } = useBrand();
  const { t } = useTranslation(["page-order"]);

  useEffect(() => {
    if (data) {
      window?.analytics.identify(data.customer.user.id, {
        ...data.customer.user,
      });

      window?.analytics.group(data.customer.organisation.id, {
        ...data.customer.organisation,
      });

      window?.analytics.track("Initiation", {
        orderId: data.id,
        orderItemsQuantity: data.items.length,
        totalAmount: data.total_amount,
        currency: data.currency,
        paymentOfferId: paymentPlan?.id || null,
        merchant: brand,
      });

      const { name: displayName, email, ...other } = data.customer.user;

      if (process.env.NEXT_PUBLIC_FULLSTORY_ORG && window.FS) {
        window.FS.identify(data.customer.organisation.id, {
          displayName,
          email,
          ...other,
        });
      }
    }
  }, [brand, data, paymentPlan?.id]);

  const isOnlyInvoice =
    paymentPlan?.scheduled_payments[0]?.allowed_payment_methods.length === 1 &&
    isInvoice(paymentPlan?.scheduled_payments[0]?.allowed_payment_methods[0]);

  return (
    <WithSupportingContent>
      <ContentBlockWrapper>
        {!embedded && (
          <ContentBlock title={t("companyDetails.title")} editable={false} complete>
            <CompanyDetails />
          </ContentBlock>
        )}
        <ContentBlock
          title={t("paymentOfferSelection.title")}
          complete={!showPaymentOffers}
          editable={!!showPaymentOffers && paymentPlans && paymentPlans?.length > 1}
        >
          <PaymentOfferSelection />
        </ContentBlock>
        {/* select complete and do not allow editing if the only payment method is invoice */}
        <ContentBlock
          title={t("paymentAuthorisation.title")}
          editable={!isOnlyInvoice}
          complete={isOnlyInvoice}
        >
          <PaymentAuthorisation />
        </ContentBlock>
        <ContentBlock>
          {/* // TODO: T&C checkbox state as context to persist on edit */}
          <PaymentConfirmation />
        </ContentBlock>
      </ContentBlockWrapper>
      {showPaymentOffers && <PaymentAuthorisationPrefetch />}
    </WithSupportingContent>
  );
};

export const getServerSideProps: GetServerSideProps = brandServerSideProps;

export default HomePage;
