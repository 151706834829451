import { CardBrands } from "@models/card";

export const cardIconSizes = {
  [CardBrands.AMERICAN_EXPRESS]: 16.039778651055,
  [CardBrands.DINERS_CLUB]: 61.134328358209,
  [CardBrands.DISCOVER]: 63.111111111111,
  [CardBrands.JCB]: 20.733445639497,
  [CardBrands.MASTERCARD]: 20.571428571429,
  [CardBrands.UNIONPAY]: 25.6,
  [CardBrands.VISA]: 25.653077180833,
};
