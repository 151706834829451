import { useBrand } from "@hooks/useBrand";
import { CardBrands } from "@models/card";
import { CardPaymentMethod } from "@models/paymentMethods";
import Image from "next/image";
import { useRouter } from "next/router";
import { FC } from "react";
import { useTranslation } from "next-i18next";
import { cardIconSizes } from "@components/PaymentMethods/Card";
import { PaymentInfoBase } from "./PaymentInfoBase";

type CardInfoProps = {
  paymentMethod: CardPaymentMethod;
};

export const CardInfo: FC<CardInfoProps> = ({ paymentMethod }) => {
  const { locale } = useRouter();
  const { brand } = useBrand();
  const { t } = useTranslation(["page-order"]);

  return (
    <PaymentInfoBase
      accountDetails={`****${paymentMethod.card.last4}`}
      accountName={paymentMethod.billing_address?.name}
      additionalInfo={`${t("paymentAuthorisation.card.expires", {
        context: brand,
      })} ${new Intl.DateTimeFormat(locale, { year: "2-digit", month: "2-digit" }).format(
        new Date(paymentMethod.card.exp_year, paymentMethod.card.exp_month - 1)
      )}`}
      icons={
        paymentMethod.card.brand !== CardBrands.UNKNOWN ? (
          <Image
            src={`/icons/cardBrands/${paymentMethod.card.brand
              .toLocaleLowerCase()
              .replace(/\s/, "-")}.svg`}
            alt={paymentMethod.card.brand}
            width={cardIconSizes[paymentMethod.card.brand]}
            height={16}
          />
        ) : undefined
      }
      isDefault={paymentMethod.is_default}
    />
  );
};
