import { useOrder } from "@hooks/useOrder";
import { PaymentMethod as BasePaymentMethod, PaymentMethodTypes } from "@models/paymentMethods";
import { useTranslation } from "next-i18next";
import { CardInfo } from "./CardInfo";
import { DirectDebitInfo } from "./DirectDebitInfo";
import { PaymentInfoBase } from "./PaymentInfoBase";

type PaymentInfoProps<PaymentMethod extends BasePaymentMethod> = {
  paymentMethod: PaymentMethod;
};

export const PaymentInfo = <PaymentMethod extends BasePaymentMethod>({
  paymentMethod,
}: PaymentInfoProps<PaymentMethod>) => {
  const { data } = useOrder();
  const { t } = useTranslation(["page-order"]);

  switch (paymentMethod.type) {
    case PaymentMethodTypes.INVOICE:
      return (
        <PaymentInfoBase
          accountName={t("paymentAuthorisation.invoice.title")}
          additionalInfo={
            (data && Object.values(data.customer.invoice_address).filter(Boolean).join(", ")) || ""
          }
        />
      );
    case PaymentMethodTypes.CARD:
      return <CardInfo paymentMethod={paymentMethod} />;
    case PaymentMethodTypes.DIRECT_DEBIT:
      return <DirectDebitInfo paymentMethod={paymentMethod} />;
    default:
      return null;
  }
};
