import styled from "styled-components";
import { mix, rgba } from "polished";

export const ButtonBase = styled.button<{ size?: "small" }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: ${({ theme }) => theme.newColors.primary.variant1};
  padding: ${({ theme, size }) =>
    `${theme.ratios.level3} ${size ? theme.ratios.level3 : theme.ratios.level7}`};
  border-radius: ${({ theme }) => theme.ratios.level1};
  cursor: pointer;
  font-size: ${({ size }) => (size ? "0.875rem" : "1rem")};
  line-height: 1.2;

  &[disabled] {
    color: ${({ theme }) => rgba(theme.newColors.primary.variant1, 0.5)};
    cursor: not-allowed;
  }
`;

export const PrimaryButton = styled(ButtonBase)`
  background-color: ${({ theme }) => theme.newColors.accent.variant1};
  color: ${({ theme }) => theme.newColors.primary.variant1};
  transition: ease-in-out 0.4s color, ease-in-out 0.4s background-color;
  font-weight: 500;

  &:hover {
    background-color: ${({ theme }) => rgba(theme.newColors.accent.variant1, 0.85)};
  }

  &[disabled] {
    background-color: ${({ theme }) => rgba(theme.newColors.accent.variant1, 0.5)};
    transition: ease-in-out 0.2s color, ease-in-out 0.2s background-color;
  }
`;

export const SecondaryButton = styled(PrimaryButton)`
  background: none;
  border: 1px solid ${({ theme }) => theme.newColors.primary.variant1};
  font-weight: 500;

  &:hover {
    background: none;
  }
`;

export const TextButton = styled(ButtonBase)`
  color: ${({ theme }) => theme.newColors.text.variant4};
  padding: ${({ theme }) => theme.ratios.level3} 0;
  transition: ease-in-out 0.4s color;
  font-weight: 400;

  &:hover {
    text-decoration: underline;
  }

  &[disabled] {
    color: ${({ theme }) => rgba(theme.newColors.primary.variant1, 0.5)};
    transition: ease-in-out 0.2s color;
  }
`;

export const WarningButton = styled(PrimaryButton)`
  background: ${({ theme }) => theme.newColors.error.variant1};
  color: ${({ theme }) => theme.newColors.text.variant3};
  font-weight: 500;

  &:hover {
    background: ${({ theme }) => mix(0.8, theme.newColors.error.variant1, "#000")};
  }
`;
