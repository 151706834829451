import styled from "styled-components";

export const SummaryTile = styled.h3`
  font-size: 1rem;
  margin: 0 0 ${({ theme }) => theme.ratios.level1};
  padding: 0;
`;

export const SummaryInfo = styled.p`
  margin: 0 0 ${({ theme }) => theme.ratios.level1};
  padding: 0;
  text-transform: "uppercase";
`;
