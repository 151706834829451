import styled, { keyframes } from "styled-components";
import { rgba } from "polished";

export default styled.div`
  margin-top: clamp(1.3rem, 3.3vw, 1.5rem);
`;

export const ModalWrapper = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => rgba(theme.colors.grey, 0.7)};
  padding: 2rem 6vw;
  z-index: 998;
  overflow: auto;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Loader = styled.div`
  margin: auto;
  border: 5px solid ${({ theme }) => rgba(theme.colors.white, 0.7)};
  animation: spin 1s linear infinite;
  animation: ${spin} 1s linear infinite;
  border-top: 5px solid ${({ theme }) => theme.colors.greyDark};
  border-radius: 50%;
  width: 50px;
  height: 50px;
`;
