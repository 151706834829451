import React, { useEffect, useState } from "react";
import {
  useGoCardlessDropin,
  GoCardlessDropinOptions,
  GoCardlessDropinOnSuccess,
  GoCardlessDropinOnExit,
} from "@gocardless/react-dropin";
import { fetcherWithToken } from "@utils/fetcher";
import { useOrderReference } from "@hooks/useOrderReference";
import { useOrder } from "@hooks/useOrder";
import { Loader } from "./DirectDebitForm.style";

type DirectDebitProps = {
  onSuccess: GoCardlessDropinOnSuccess;
  onExit: GoCardlessDropinOnExit;
};

const DropIn = ({
  config,
  onError,
}: {
  config: GoCardlessDropinOptions;
  onError?: (error: ErrorEvent) => void;
}) => {
  const { open, ready, error } = useGoCardlessDropin({ ...config });

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [open, ready]);

  useEffect(() => {
    if (error) {
      onError?.(error);
    }
  }, [error, onError]);

  return null;
};

// Example checkout flow, where we create a Billing Request Flow ID by talking
// with our backend API.
export const DirectDebitForm = ({ onSuccess, onExit }: DirectDebitProps) => {
  const [flowID, setFlowID] = useState<string>();
  const { data, organisationId, isLoading } = useOrder();
  const { key } = useOrderReference();

  useEffect(() => {
    async function createFlow() {
      if (organisationId) {
        const GoCardlessBillingRequest = await fetcherWithToken<{
          billing_request_flow_id: string;
        }>(key, {
          method: "POST",
          body: JSON.stringify({
            flow: "billing_request",
            currency: data?.currency,
          }),
        })(`/organisations/${organisationId}/gocardless_setup?domain=${window.location.host}`);

        setFlowID(GoCardlessBillingRequest.billing_request_flow_id);
      }
    }

    if (!isLoading && !flowID) {
      void createFlow();
    }
  }, [isLoading, flowID, data, organisationId, key]);

  // Only show the button once we have a Billing Request Flow ID
  if (flowID && !isLoading) {
    return (
      <DropIn
        config={{
          billingRequestFlowID: flowID,
          environment:
            process.env.VERCEL_GIT_COMMIT_REF &&
            ["production", "iframe"].includes(process.env.VERCEL_GIT_COMMIT_REF)
              ? "live"
              : "sandbox",
          onSuccess,
          onExit,
        }}
      />
    );
  }

  return <Loader />;
};
