import { useState } from "react";
import { PaymentMethod as BasePaymentMethod, PaymentMethodTypes } from "@models/paymentMethods";
import { fetcherWithToken } from "@utils/fetcher";
import { useOrder } from "@hooks/useOrder";
import { useOrderReference } from "@hooks/useOrderReference";
import { captureException } from "@sentry/nextjs";
import { useBrand } from "@hooks/useBrand";
import { useTranslation } from "next-i18next";
import { useLocale } from "@hooks/useLocale";
import { apiExtension } from "@components/PaymentMethods/apiExtension";
import { Dialog } from "@components/Dialog";
import { MoreOptionsMenuItem } from "./MoreOptions.style";

type DeletePaymentMethodProps<PaymentMethod> = {
  paymentMethod: PaymentMethod;
  onOptionClick: () => void;
  onSubmit: (paymentMethod: PaymentMethod) => void;
};

const endingIn = (paymentMethod: BasePaymentMethod): string => {
  switch (paymentMethod.type) {
    case PaymentMethodTypes.DIRECT_DEBIT:
      return paymentMethod.direct_debit.account_number_ending;
    case PaymentMethodTypes.CARD:
      return paymentMethod.card.last4;
    default:
      return "";
  }
};

export const DeletePaymentMethod = <PaymentMethod extends BasePaymentMethod>({
  paymentMethod,
  onOptionClick,
  onSubmit,
}: DeletePaymentMethodProps<PaymentMethod>) => {
  const [isOpen, setIsOpen] = useState(false);
  const { organisationId } = useOrder();
  const { key } = useOrderReference();
  const { brand } = useBrand();
  const { t } = useTranslation(["page-order"]);
  const { locale } = useLocale();

  const handleOnOptionClick = () => {
    setIsOpen(true);
    onOptionClick();
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSubmit = () => {
    if (organisationId) {
      fetcherWithToken<PaymentMethod>(key, {
        method: "DELETE",
      })(`/organisations/${organisationId}/${apiExtension(paymentMethod.type)}/${paymentMethod.id}`)
        .then(() => {
          onSubmit(paymentMethod);
          window?.analytics.track("Payment method deleted", paymentMethod);
        })
        .catch((err) => {
          captureException(err);
        });
    } else {
      captureException(new Error("No organisation ID available"));
    }
  };

  return (
    <>
      <MoreOptionsMenuItem type="button" onClick={handleOnOptionClick} role="menuitem" warning>
        {t(`paymentAuthorisation.${paymentMethod.type}.moreOptions.delete`, { context: brand })}
      </MoreOptionsMenuItem>
      <Dialog
        isOpen={isOpen}
        onClose={handleClose}
        onSubmit={handleSubmit}
        submitButtonText={t("paymentAuthorisation.confirmDelete", {
          context: brand,
        })}
      >
        <h4 className="fs-mask">
          {t(`paymentAuthorisation.${paymentMethod.type}.deleteModal.title`, {
            endingIn: endingIn(paymentMethod),
            brand:
              paymentMethod.type === PaymentMethodTypes.CARD ? paymentMethod.card.brand : undefined,
            context: brand,
          })}
        </h4>
        <p>
          <small className="fs-mask">
            {paymentMethod.type === PaymentMethodTypes.CARD &&
              `${t("paymentAuthorisation.card.expires", {
                context: brand,
              })}: ${new Intl.DateTimeFormat(locale, {
                year: "2-digit",
                month: "2-digit",
              }).format(new Date(paymentMethod.card.exp_year, paymentMethod.card.exp_month - 1))}`}
            {paymentMethod.type === PaymentMethodTypes.DIRECT_DEBIT &&
              `${t("paymentAuthorisation.direct_debit.bank", {
                context: brand,
              })}: ${paymentMethod.direct_debit.bank_name}`}
          </small>
        </p>
        <p>
          {t(`paymentAuthorisation.${paymentMethod.type}.deleteModal.supportingText`, {
            context: brand,
          })}
        </p>
      </Dialog>
    </>
  );
};
