import type { PaymentMethod as BasePaymentMethod } from "@models/paymentMethods";
import { fetcherWithToken } from "@utils/fetcher";
import { useOrder } from "@hooks/useOrder";
import { useOrderReference } from "@hooks/useOrderReference";
import { captureException } from "@sentry/nextjs";
import { apiExtension } from "@components/PaymentMethods/apiExtension";
import { useBrand } from "@hooks/useBrand";
import { useTranslation } from "next-i18next";
import { MoreOptionsMenuItem } from "./MoreOptions.style";

type DefaultPaymentMethodProps<PaymentMethod> = {
  paymentMethod: PaymentMethod;
  onOptionClick: () => void;
  onSubmit: (paymentMethod: PaymentMethod) => void;
};

export const DefaultPaymentMethod = <PaymentMethod extends BasePaymentMethod>({
  paymentMethod,
  onOptionClick,
  onSubmit,
}: DefaultPaymentMethodProps<PaymentMethod>) => {
  const { organisationId } = useOrder();
  const { key } = useOrderReference();
  const { brand } = useBrand();
  const { t } = useTranslation(["page-order"]);

  const handleSettingAsDefault = () => {
    if (organisationId) {
      fetcherWithToken<PaymentMethod>(key, {
        method: "PATCH",
        body: JSON.stringify({
          is_default: true,
        }),
      })(`/organisations/${organisationId}/${apiExtension(paymentMethod.type)}/${paymentMethod.id}`)
        .then((updatedPaymentMethod) => {
          onSubmit(updatedPaymentMethod);
          window?.analytics.track("Payment method set as default", paymentMethod);
        })
        .catch((err) => {
          captureException(err);
        });
    } else {
      captureException(new Error("No organisation ID available"));
    }
  };

  const handleOnOptionClick = () => {
    handleSettingAsDefault();
    onOptionClick();
  };

  return (
    <MoreOptionsMenuItem type="button" onClick={handleOnOptionClick} role="menuitem">
      {t(`paymentAuthorisation.${paymentMethod.type}.moreOptions.default`, { context: brand })}
    </MoreOptionsMenuItem>
  );
};
