import { Trans, useTranslation } from "next-i18next";
import Wrapper, { UspArticle, UspTitle, UspSentence } from "./USP.style";

export const USP = () => {
  const { t } = useTranslation("component-usp");

  return (
    <Wrapper>
      <UspArticle>
        <aside>
          <svg
            width="19"
            height="20"
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.63696 7.14033L9.63259 4.02551L15.4333 4.44724L16.081 10.2271L12.9464 13.4241"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.1751 1.72412L13.0449 6.84475"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1.23218 1.72412L18.1991 18.691"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.3224 15.3047L8.24485 18.4029C7.77341 18.8776 7.00843 18.8863 6.52626 18.4226L1.84102 13.9166C1.34899 13.4434 1.33857 12.6593 1.81785 12.1732L4.80452 9.14404"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </aside>
        <div>
          <UspTitle>{t("noFeeNoInterest.title")}</UspTitle>
          <UspSentence>{t("noFeeNoInterest.description")}</UspSentence>
        </div>
      </UspArticle>
      <UspArticle>
        <aside>
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_412_3442)">
              <path
                d="M17.8575 3.71436H2.14317C1.35419 3.71436 0.7146 4.35395 0.7146 5.14293V15.8572C0.7146 16.6462 1.35419 17.2858 2.14317 17.2858H17.8575C18.6464 17.2858 19.286 16.6462 19.286 15.8572V5.14293C19.286 4.35395 18.6464 3.71436 17.8575 3.71436Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.7146 8.71436H19.286"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.5718 13.7144H15.7146"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_412_3442">
                <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
              </clipPath>
            </defs>
          </svg>
        </aside>
        <div>
          <UspTitle>{t("secureCheckout.title")}</UspTitle>
          <UspSentence>{t("secureCheckout.description")}</UspSentence>
        </div>
      </UspArticle>
      <UspArticle>
        <aside>
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 1.5C8.37203 1.50051 6.77467 1.94258 5.37807 2.77913C3.98148 3.61568 2.83793 4.81537 2.06925 6.25044C1.30056 7.68551 0.93552 9.30221 1.013 10.9283C1.09048 12.5545 1.60758 14.1291 2.50923 15.4846L1 19.5L6.05385 18.5862C7.2708 19.1808 8.60628 19.4932 9.96074 19.4999C11.3152 19.5066 12.6537 19.2075 13.8765 18.6249C15.0993 18.0423 16.1748 17.1912 17.0229 16.1351C17.8709 15.079 18.4697 13.845 18.7745 12.5253C19.0793 11.2056 19.0823 9.83403 18.7832 8.51298C18.4842 7.19192 17.8908 5.9554 17.0474 4.89558C16.2039 3.83577 15.1321 2.98 13.9119 2.39207C12.6917 1.80413 11.3545 1.4992 10 1.5V1.5Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.92285 8.42312C7.92285 8.01234 8.04466 7.61079 8.27288 7.26924C8.50109 6.92769 8.82546 6.66149 9.20497 6.50429C9.58448 6.34709 10.0021 6.30596 10.405 6.3861C10.8078 6.46624 11.1779 6.66405 11.4684 6.95451C11.7588 7.24497 11.9567 7.61504 12.0368 8.01793C12.1169 8.42081 12.0758 8.83841 11.9186 9.21792C11.7614 9.59743 11.4952 9.9218 11.1537 10.15C10.8121 10.3782 10.4106 10.5 9.99977 10.5V11.8847"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.0001 13.9615C9.79474 13.9615 9.59397 14.0225 9.42319 14.1366C9.25242 14.2507 9.11932 14.4129 9.04072 14.6026C8.96212 14.7924 8.94155 15.0012 8.98162 15.2026C9.02169 15.404 9.1206 15.5891 9.26583 15.7343C9.41106 15.8795 9.5961 15.9784 9.79754 16.0185C9.99898 16.0586 10.2078 16.038 10.3975 15.9594C10.5873 15.8808 10.7495 15.7477 10.8636 15.5769C10.9777 15.4062 11.0386 15.2054 11.0386 15C11.035 14.7257 10.9244 14.4636 10.7305 14.2697C10.5365 14.0757 10.2744 13.9651 10.0001 13.9615Z"
              fill="currentColor"
            />
          </svg>
        </aside>
        <div>
          <UspTitle>{t("paymentSupport.title")}</UspTitle>
          <UspSentence>
            <Trans
              t={t}
              components={{
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                a: <a />,
              }}
            >
              paymentSupport.description
            </Trans>
          </UspSentence>
        </div>
      </UspArticle>
    </Wrapper>
  );
};
