import { Children, cloneElement, isValidElement } from "react";
import Wrapper, {
  EditButton,
  ContentBlockNumberWrapper,
  ContentBlockNumber,
  Header,
  Title,
  TitleWrapper,
  Content,
} from "./ContentBlock.style";

export const ContentBlock = ({
  title,
  number,
  disabled = false,
  complete = false,
  editable = true,
  onContinue,
  onEdit,
  children,
}: ContentBlockProps) => {
  if (disabled && !title) {
    return null;
  }

  const childrenWithState = Children.map(children, (child) => {
    if (isValidElement<ContentBlockChildProps>(child)) {
      return cloneElement(child, {
        isComplete: complete,
        onClick: onContinue,
      });
    }

    return child;
  });

  return (
    <Wrapper>
      {title && (
        <Header>
          <TitleWrapper>
            {complete && !disabled ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="12" cy="12" r="11.25" fill="white" stroke="#132E34" strokeWidth="1.5" />
                <path
                  d="M7 13.4954L9.1 15.7495C9.17086 15.8264 9.26166 15.8888 9.36558 15.9322C9.46949 15.9756 9.58383 15.9987 9.7 16C9.8143 16.0011 9.92746 15.9809 10.0313 15.9409C10.135 15.9009 10.2268 15.8421 10.3 15.7688L17 9"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <ContentBlockNumberWrapper disabled={disabled}>
                <ContentBlockNumber disabled={disabled}>{number}</ContentBlockNumber>
              </ContentBlockNumberWrapper>
            )}
            <Title disabled={disabled}>{title}</Title>
          </TitleWrapper>
          {complete && editable && onEdit && (
            <EditButton type="button" onClick={onEdit}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.57115 10.2857L5.14258 10.9029L5.71401 7.42859L12.2626 0.902875C12.3688 0.795756 12.4952 0.710734 12.6345 0.652713C12.7738 0.594692 12.9231 0.564819 13.074 0.564819C13.2249 0.564819 13.3743 0.594692 13.5135 0.652713C13.6528 0.710734 13.7792 0.795756 13.8854 0.902875L15.0969 2.1143C15.204 2.22055 15.289 2.34695 15.347 2.48622C15.405 2.62548 15.4349 2.77486 15.4349 2.92573C15.4349 3.0766 15.405 3.22598 15.347 3.36525C15.289 3.50452 15.204 3.63092 15.0969 3.73716L8.57115 10.2857Z"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.7141 10.8571V14.2857C13.7141 14.5888 13.5937 14.8795 13.3794 15.0938C13.1651 15.3082 12.8744 15.4286 12.5713 15.4286H1.71415C1.41104 15.4286 1.12035 15.3082 0.906024 15.0938C0.691697 14.8795 0.571289 14.5888 0.571289 14.2857V3.42856C0.571289 3.12546 0.691697 2.83477 0.906024 2.62044C1.12035 2.40611 1.41104 2.28571 1.71415 2.28571H5.14272"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </EditButton>
          )}
        </Header>
      )}
      {!disabled && (
        <Content complete={complete} hasPadding={!!title}>
          {childrenWithState}
        </Content>
      )}
    </Wrapper>
  );
};
