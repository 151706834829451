import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import "react-loading-skeleton/dist/skeleton.css";
import { useBrand } from "@hooks/useBrand";
import Skeleton from "react-loading-skeleton";
import { useOrder } from "@hooks/useOrder";
import { useLocale } from "@hooks/useLocale";
import { currencyFormatter } from "@utils/currencyFormat";
import Wrapper, {
  OrderSummaryHeader,
  OrderSummaryTitle,
  OrderSummaryBtn,
  OrderSummaryContent,
  TotalValueRow,
} from "./OrderSummary.style";

export const OrderSummary = (): ReactElement => {
  const { t } = useTranslation(["page-order", "common"]);
  const { data, isLoading } = useOrder();
  const { brand } = useBrand();
  const { locale } = useLocale();
  const [products, setProducts] = useState<number>();
  const [deliveryCharges, setDeliveryCharges] = useState<number>();
  const [isOpen, setIsOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  const updateMedia = () => {
    setIsDesktop(window.visualViewport.width >= 768);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    updateMedia();
    window.visualViewport.addEventListener("resize", updateMedia);

    return () => {
      window.visualViewport.removeEventListener("resize", updateMedia);
    };
  }, []);

  useEffect(() => {
    if (data?.items) {
      const partitionedItems = data?.items.reduce<[number, number]>(
        ([shippingTotal, productTotal], currentItem) => {
          if (currentItem.type !== "shipping") {
            return [
              shippingTotal + (currentItem.total_amount - currentItem.tax_amount),
              productTotal,
            ];
          }

          return [
            shippingTotal,
            productTotal + (currentItem.total_amount - currentItem.tax_amount),
          ];
        },
        [0, 0]
      );

      setProducts(partitionedItems[0]);
      setDeliveryCharges(partitionedItems[1]);
    }
  }, [data?.items]);

  return (
    <Wrapper data-testid="orderSummary">
      <OrderSummaryHeader>
        {isDesktop ? (
          <OrderSummaryTitle>
            {t("page-order:orderSummary.title", { context: brand })}
          </OrderSummaryTitle>
        ) : (
          <OrderSummaryBtn
            type="button"
            onClick={handleToggle}
            aria-controls="orderSummaryContent"
            aria-expanded={isOpen}
          >
            <span>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.64285 1.71423H5.21428L6.45713 14.0714C6.50879 14.412 6.68182 14.7225 6.94434 14.9457C7.20686 15.1688 7.54119 15.2896 7.88571 15.2857H16.8857C17.1972 15.3019 17.5055 15.2158 17.7635 15.0404C18.0214 14.8649 18.2149 14.6099 18.3143 14.3142L20.2143 8.59995C20.2851 8.38511 20.3039 8.15652 20.2692 7.933C20.2344 7.70947 20.147 7.4974 20.0143 7.31423C19.876 7.11949 19.6911 6.96253 19.4764 6.85771C19.2618 6.75289 19.0243 6.70356 18.7857 6.71423H5.71428"
                  stroke="#1A2D33"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.6431 20.2856C16.2486 20.2856 15.9289 19.9658 15.9289 19.5713C15.9289 19.1769 16.2486 18.8571 16.6431 18.8571C17.0376 18.8571 17.3574 19.1769 17.3574 19.5713C17.3574 19.9658 17.0376 20.2856 16.6431 20.2856Z"
                  stroke="#1A2D33"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.357 20.2856C6.96251 20.2856 6.64272 19.9658 6.64272 19.5713C6.64272 19.1769 6.96251 18.8571 7.357 18.8571C7.75149 18.8571 8.07129 19.1769 8.07129 19.5713C8.07129 19.9658 7.75149 20.2856 7.357 20.2856Z"
                  stroke="#1A2D33"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <OrderSummaryTitle>
                {t("page-order:orderSummary.title", { context: brand })}
              </OrderSummaryTitle>
            </span>
            <span>
              {data && !isLoading ? (
                currencyFormatter(locale, data.total_amount || 0, data.currency, true)
              ) : (
                <Skeleton width={90} height={32} style={{ verticalAlign: "middle" }} />
              )}
              <svg
                width="14"
                height="12"
                viewBox="0 0 14 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  transform: isOpen ? "rotateX(180deg)" : "rotateX(0deg)",
                  transition: "transform 0.6s",
                  transformStyle: "preserve-3d",
                }}
              >
                <path
                  d="M12.625 3.65552L7.265 9.01502C7.23022 9.04985 7.18891 9.07748 7.14344 9.09634C7.09797 9.11519 7.04922 9.12489 7 9.12489C6.95078 9.12489 6.90203 9.11519 6.85656 9.09634C6.81109 9.07748 6.76978 9.04985 6.735 9.01502L1.375 3.65552"
                  stroke="#132E34"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </OrderSummaryBtn>
        )}
      </OrderSummaryHeader>
      <OrderSummaryContent
        aria-hidden={isDesktop ? undefined : isOpen}
        id="orderSummaryContent"
        isOpen={isDesktop ? true : isOpen}
      >
        <div>
          <table data-testid="orderSummary.summary">
            <tbody>
              <tr>
                <th>{t("page-order:orderSummary.summary.basketSubtotal", { context: brand })}</th>
                <td>
                  {products && data && !isLoading ? (
                    currencyFormatter(locale, products, data.currency)
                  ) : (
                    <Skeleton width={60} />
                  )}
                </td>
              </tr>
              <tr>
                <th>{t("page-order:orderSummary.summary.totalForDelivery", { context: brand })}</th>
                <td>
                  {isLoading && <Skeleton width={60} />}
                  {data && !isLoading && deliveryCharges
                    ? currencyFormatter(locale, deliveryCharges, data.currency)
                    : t("common:free").toUpperCase()}
                </td>
              </tr>
              <tr>
                <th>{t("page-order:orderSummary.summary.vat", { context: brand })}</th>
                <td>
                  {data && !isLoading ? (
                    currencyFormatter(locale, data.tax_amount || 0, data.currency)
                  ) : (
                    <Skeleton width={60} />
                  )}
                </td>
              </tr>
              <TotalValueRow>
                <th>{t("page-order:orderSummary.summary.totalIncVAT", { context: brand })}</th>
                <td>
                  {data && !isLoading ? (
                    currencyFormatter(locale, data.total_amount || 0, data.currency)
                  ) : (
                    <Skeleton width={90} style={{ verticalAlign: "middle" }} />
                  )}
                </td>
              </TotalValueRow>
            </tbody>
          </table>
        </div>
      </OrderSummaryContent>
    </Wrapper>
  );
};
