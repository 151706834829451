import { FC, memo, ReactElement } from "react";
import { useBrand } from "@hooks/useBrand";
import { useTranslation } from "next-i18next";
import { PaymentInfoWrapper, PaymentInfoLeft, PaymentInfoRight } from "./PaymentInfoBase.style";

type PaymentInfoProps = {
  accountDetails?: string;
  accountName?: string;
  additionalInfo: string;
  icons?: ReactElement | ReactElement[];
  isDefault?: boolean;
};

export const PaymentInfoBase: FC<PaymentInfoProps> = memo(
  ({ accountDetails, accountName, additionalInfo, icons, isDefault }) => {
    const { brand } = useBrand();
    const { t } = useTranslation(["page-order"]);

    return (
      <PaymentInfoWrapper>
        <PaymentInfoLeft>
          <p className="fs-mask" data-testid="paymentInfo.accountDetails">
            {accountName && (
              <span
                className="fs-mask"
                data-testid="paymentInfo.accountName"
                style={{ textTransform: "capitalize" }}
              >
                {accountName}
              </span>
            )}{" "}
            {accountDetails && accountDetails}{" "}
            {isDefault && (
              <small>({t("paymentAuthorisation.card.default", { context: brand })})</small>
            )}
          </p>
          <p>
            <small>{additionalInfo}</small>
          </p>
        </PaymentInfoLeft>
        <PaymentInfoRight>{icons && <div>{icons}</div>}</PaymentInfoRight>
      </PaymentInfoWrapper>
    );
  }
);
