import { Address } from "./address";
import { Card } from "./card";
import { DirectDebit } from "./directDebit";
import { Invoice } from "./invoice";

export enum PaymentMethodTypes {
  CARD = "card",
  INVOICE = "invoice",
  DIRECT_DEBIT = "direct_debit",
}

type PaymentMethodBase = {
  id: string;
  billing_address: Address | null;
  is_default: boolean;
};

export type DirectDebitPaymentMethod = PaymentMethodBase & {
  type: PaymentMethodTypes.DIRECT_DEBIT;
  direct_debit: DirectDebit;
};

export type CardPaymentMethod = PaymentMethodBase & {
  type: PaymentMethodTypes.CARD;
  card: Card;
};

export type InvoicePaymentMethod = PaymentMethodBase & {
  type: PaymentMethodTypes.INVOICE;
  invoice: Invoice;
};

export type PaymentMethod = DirectDebitPaymentMethod | CardPaymentMethod | InvoicePaymentMethod;

type PaymentMethodsBase = {
  count: number;
  next: string | null;
  previous: string | null;
};

export type PaymentMethods<Result extends PaymentMethod> = PaymentMethodsBase & {
  results: Result[];
};

export type GoCardlessPaymentMethods = PaymentMethodsBase & {
  results: DirectDebitPaymentMethod[];
};

export type StripePaymentMethods = PaymentMethodsBase & {
  results: CardPaymentMethod[];
};

export type HokodoPaymentMethods = PaymentMethodsBase & {
  results: InvoicePaymentMethod[];
};

// Type Guards
export const isDirectDebit = (
  paymentMethod?: PaymentMethod
): paymentMethod is DirectDebitPaymentMethod =>
  !!paymentMethod && paymentMethod.type === PaymentMethodTypes.DIRECT_DEBIT;

export const isCard = (paymentMethod?: PaymentMethod): paymentMethod is CardPaymentMethod =>
  !!paymentMethod && paymentMethod.type === PaymentMethodTypes.CARD;

export const isInvoice = (
  paymentMethod?: Pick<PaymentMethod, "type">
): paymentMethod is InvoicePaymentMethod =>
  !!paymentMethod && paymentMethod.type === PaymentMethodTypes.INVOICE;
