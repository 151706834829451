import { DirectDebitPaymentMethod } from "@models/paymentMethods";
import { FC, memo } from "react";
import Image from "next/image";
import { PaymentInfoBase } from "./PaymentInfoBase";

type DirectDebitInfoProps = {
  paymentMethod: DirectDebitPaymentMethod;
};

export const DirectDebitInfo: FC<DirectDebitInfoProps> = memo(({ paymentMethod }) => (
  <PaymentInfoBase
    accountDetails={`******${paymentMethod.direct_debit.account_number_ending}`}
    accountName={`${paymentMethod.direct_debit.account_holder_name.toLowerCase()}`}
    additionalInfo={paymentMethod.direct_debit.bank_name}
    icons={
      <Image src="/icons/direct-debit.svg" alt="Direct Debit" width={48.827586206897} height={16} />
    }
    isDefault={paymentMethod.is_default}
  />
));
