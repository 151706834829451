import { rgba } from "polished";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`;

export const Loader = styled.div`
  margin: auto;
  border: 5px solid ${({ theme }) => rgba(theme.colors.white, 0.7)};
  animation: spin 1s linear infinite;
  animation: ${spin} 1s linear infinite;
  border-top: 5px solid ${({ theme }) => theme.colors.greyDark};
  border-radius: 50%;
  width: 50px;
  height: 50px;
`;
