import { ChangeEvent, FormEvent, ReactElement, useCallback, useEffect, useState } from "react";
import { Input } from "@components/Input";
import { fetcher, ErrorLike } from "@utils/fetcher";
import { SupportMessage } from "@components/SupportMessage";
import * as Sentry from "@sentry/nextjs";
import { useSessionId } from "@hooks/useSessionId";
import { DeferredPayment } from "@models/deferredPayment";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { usePaymentMethod } from "@hooks/usePaymentMethod";
import { useBrand } from "@hooks/useBrand";
import { useTranslation, Trans } from "next-i18next";
import { useOrder } from "@hooks/useOrder";
import { useOrderReference } from "@hooks/useOrderReference";
import { Button } from "@components/Button";
import {
  InputWrapper,
  ButtonWrapper,
  ErrorMessage,
  ModalWrapper,
  Modal,
  Loader,
  ModalContent,
} from "./PaymentConfirmation.style";

export const PaymentConfirmation = (): ReactElement => {
  const { t } = useTranslation(["page-order", "common"]);
  const { key } = useOrderReference();
  const { data, paymentPlan, isLoading, mutate } = useOrder();
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [hasError, setHasError] = useState<boolean>();
  const { paymentMethod } = usePaymentMethod();

  const { brand } = useBrand();

  const { sessionId } = useSessionId();

  const handleCheckboxChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTermsAgreed(e.target.checked);
  }, []);

  const handleBlockingUser = useCallback((event: BeforeUnloadEvent) => {
    // Note: this is the correct approach for adding a message
    // See - https://dev.to/chromiumdev/sure-you-want-to-leavebrowser-beforeunload-event-4eg5
    // eslint-disable-next-line no-param-reassign
    event.returnValue = `Are you sure you want to leave?`;
  }, []);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    setFormLoading(true);
    window.addEventListener("beforeunload", handleBlockingUser);

    if (!paymentMethod) {
      const err = new Error("Payment method is undefined") as ErrorLike;
      Sentry.captureException(err);
      setHasError(true);
      setFormLoading(false);
    } else if (!sessionId) {
      const err = new Error("Session ID is undefined") as ErrorLike;
      Sentry.captureException(err);
      setHasError(true);
      setFormLoading(false);
    } else {
      try {
        const deferredPayment = await fetcher<DeferredPayment>("/payment/deferred_payments", {
          method: "POST",
          headers: new Headers({
            authorization: `OrderKey ${key}`,
            "content-type": "application/json",
          }),
          body: JSON.stringify({
            payment_plan: paymentPlan?.id,
            payment_method: paymentMethod.id,
            session_id: sessionId,
          }),
        });

        window?.analytics.track("Trade credit form submitted", {
          deferredPayment,
          paymentMethod,
        });

        // Timeout to allow analytics outbound calls
        // Alternative to callback - https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track
        setTimeout(() => {
          window.removeEventListener("beforeunload", handleBlockingUser);

          if (data) {
            void mutate(
              {
                ...data,
                deferred_payment: deferredPayment,
              },
              false
            );
          } else {
            void mutate();
          }
        }, 500);
      } catch (err) {
        Sentry.captureException(err);
        setHasError(true);
        setFormLoading(false);
      }
    }
  };

  useEffect(
    // eslint-disable-next-line arrow-body-style
    () => {
      return () => {
        window.removeEventListener("beforeunload", handleBlockingUser);
      };
    },
    [handleBlockingUser]
  );

  return (
    <>
      <form method="post" onSubmit={handleSubmit} data-testid="paymentConfirmation.form">
        <InputWrapper>
          <Input
            id="terms"
            type="checkbox"
            data-testid="paymentConfirmation.checkbox"
            checked={termsAgreed}
            onChange={handleCheckboxChange}
            hasMargin={false}
            label={
              <Trans
                t={t}
                values={{
                  brand,
                  supportEmail: t("common:supportEmail", { context: brand }),
                }}
                tOptions={{
                  context: brand,
                }}
                components={{
                  termsLink: (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={
                        data?.payment_offer?.legals?.terms_url ??
                        t("common:links.terms", { context: brand })
                      }
                    >
                      credit terms
                    </a>
                  ),
                  privacyPolicyLink: (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={t("common:links.privacyPolicy", { context: brand })}
                    >
                      privacy policy
                    </a>
                  ),
                }}
              >
                {`paymentConfirmation.checkbox.${data?.payment_offer?.legals?.type || "default"}`}
              </Trans>
            }
          />
        </InputWrapper>
        {hasError && (
          <ErrorMessage data-testid="paymentConfirmation.error">
            {t("paymentConfirmation.error", { context: brand })}{" "}
            <SupportMessage
              subject={t("paymentConfirmation.submissionError", {
                paymentPlanId: paymentPlan?.id,
                context: brand,
              })}
              error
            />
          </ErrorMessage>
        )}
        <ButtonWrapper>
          {(formLoading && termsAgreed) || !paymentMethod || !sessionId || isLoading ? (
            <Skeleton style={{ display: "block", width: "100%" }} height={47} />
          ) : (
            <Button
              type="submit"
              disabled={!termsAgreed}
              data-testid="paymentConfirmation.submitButton"
              style={{ width: "100%" }}
            >
              {t("paymentConfirmation.confirm", { context: brand })}
            </Button>
          )}
        </ButtonWrapper>
      </form>

      {formLoading && (
        <ModalWrapper>
          <Modal role="dialog" aria-modal="true">
            <ModalContent>
              <h4>{t("paymentConfirmation.processingOrder.title", { context: brand })}</h4>
              <Loader />
              <p>{t("paymentConfirmation.processingOrder.body", { context: brand })}</p>
            </ModalContent>
          </Modal>
        </ModalWrapper>
      )}
    </>
  );
};
