import { ReactElement } from "react";
import { Input } from "@components/Input";
import { isInvoice, PaymentMethod as BasePaymentMethod } from "@models/paymentMethods";
import { MoreOptions } from "@components/PaymentMethods/MoreOptions";
import { PaymentInfo } from "./PaymentInfo";
import { PaymentDetailsWrapper } from "./PaymentDetails.style";

type SavedPaymentProps<PaymentMethod extends BasePaymentMethod> = {
  paymentMethod: PaymentMethod;
  isChecked?: boolean;
  onChange?: () => void;
  onDefault?: (paymentMethod: PaymentMethod) => void;
  onDelete?: (paymentMethod: PaymentMethod) => void;
};

export const PaymentDetails = <PaymentMethod extends BasePaymentMethod>({
  paymentMethod,
  isChecked,
  onChange,
  onDefault,
  onDelete,
}: SavedPaymentProps<PaymentMethod>): ReactElement => (
  <PaymentDetailsWrapper>
    {onChange ? (
      <Input
        type="radio"
        id={paymentMethod.id}
        value={paymentMethod.id}
        onChange={onChange}
        checked={isChecked}
        name={paymentMethod.type}
        label={<PaymentInfo paymentMethod={paymentMethod} />}
        hasMargin={false}
      />
    ) : (
      <PaymentInfo paymentMethod={paymentMethod} />
    )}
    {onDefault && onDelete && !isInvoice(paymentMethod) && (
      <MoreOptions paymentMethod={paymentMethod} onDefault={onDefault} onDelete={onDelete} />
    )}
  </PaymentDetailsWrapper>
);
