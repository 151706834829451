export enum CardBrands {
  VISA = "visa",
  AMERICAN_EXPRESS = "amex",
  MASTERCARD = "mastercard",
  DINERS_CLUB = "diners",
  DISCOVER = "discover",
  JCB = "jcb",
  UNIONPAY = "unionpay",
  UNKNOWN = "unknown",
}

export type Card = {
  brand: CardBrands;
  exp_month: number;
  exp_year: number;
  last4: string;
};
