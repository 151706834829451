import { ButtonBase } from "@components/Button/Button.styled";
import { mix } from "polished";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  align-content: center;
`;

export const MoreOptionsButton = styled.button`
  position: relative;
  background: none;
  color: inherit;
  padding: 0;
  margin: 0 0 0 -0.5rem;
  text-align: center;
  min-width: 38px;
  cursor: pointer;

  &:after {
    content: "•••";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    font-size: 15px;
    letter-spacing: 3px;
    margin-top: 2px;
  }
`;

export const MoreOptionsMenu = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen = false }) => (isOpen ? "block" : "none")};
  position: absolute;
  z-index: 999;
  top: calc(50% + 24px);
  right: -4px;
  border: 1px solid ${({ theme }) => theme.newColors.background.variant4};
  border-radius: ${({ theme }) => theme.ratios.level1};
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);

  &:before {
    content: "";
    position: absolute;
    background: ${({ theme }) => theme.colors.white};
    width: 0.8rem;
    height: 0.8rem;
    top: -0.4rem;
    right: 0.9rem;
    transform: rotate(45deg);
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid ${({ theme }) => theme.newColors.background.variant4};
  }

  &:after {
    content: "";
    position: absolute;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    width: 4rem;
    height: 2rem;
    top: 0;
    right: 0;
  }
`;

export const MoreOptionsMenuItem = styled(ButtonBase)<{ warning?: boolean }>`
  position: relative;
  z-index: 2;
  white-space: nowrap;
  width: 100%;
  color: ${({ theme, warning }) => (warning ? theme.newColors.error.variant1 : "inherit")};
  background: ${({ theme }) => theme.newColors.background.variant2};
  font-weight: 400;
  border-radius: 0;
  text-align: left;
  justify-content: left;
  // TODO: work out how to pass this through to the button base
  padding: ${({ theme }) => theme.ratios.level3};
  font-size: ${({ theme }) => theme.typography.small.fontSize};

  &:first-child {
    border-radius: ${({ theme }) => `${theme.ratios.level1} ${theme.ratios.level1} 0 0`};
  }

  &:last-child {
    border-radius: ${({ theme }) => `0 0 ${theme.ratios.level1} ${theme.ratios.level1}`};
  }

  &:only-child {
    border-radius: ${({ theme }) => theme.ratios.level1};
  }

  &:hover {
    background: ${({ theme }) =>
      mix(0.4, theme.newColors.background.variant4, theme.newColors.background.variant2)};
  }
`;
