import { FC } from "react";
import { useOrder } from "@hooks/useOrder";
import "react-loading-skeleton/dist/skeleton.css";
import { useTranslation } from "next-i18next";
import { useBrand } from "@hooks/useBrand";
import Wrapper, { Link } from "./CancelAndReturn.style";

export const CancelAndReturn: FC = () => {
  const { t } = useTranslation(["common"]);
  const { data } = useOrder();
  const { brand } = useBrand();

  const handleClick = () => {
    window?.analytics.track("Cancel link clicked");
  };

  return (
    <Wrapper>
      <Link
        href={data?.payment_offer?.urls.cancel}
        onClick={data?.payment_offer?.urls.cancel ? handleClick : undefined}
      >
        {t("common:cancelAndReturnToMerchant", { context: brand })}
      </Link>
    </Wrapper>
  );
};
