import styled from "styled-components";

export const ContentWrapper = styled.div<{ noGap?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ noGap }) => (noGap ? undefined : "clamp(1.3rem, 3.3vw, 1.5rem)")};
`;

export const AddNewPaymentMethod = styled.div`
  margin: 0 auto;
`;

export const BackButtonWrapper = styled.div`
  margin: ${({ theme }) => `-${theme.ratios.level3} 0 ${theme.ratios.level3}`};
`;

export const BackIcon = styled.span`
  height: 1em;
  width: 1em;
  margin-left: -5px;
`;
