import { ChangeEvent, Fragment, ReactElement, useState } from "react";
import {
  PaymentMethodTypes,
  PaymentMethod as BasePaymentMethod,
  CardPaymentMethod,
  DirectDebitPaymentMethod,
} from "@models/paymentMethods";
import { useOrder } from "@hooks/useOrder";
import { useBrand } from "@hooks/useBrand";
import { Input } from "@components/Input";
import { useTranslation } from "next-i18next";
import { NewPaymentMethod } from "@components/PaymentMethods/NewPaymentMethod";
import { CardTitle } from "@components/PaymentMethods/Card";
import { DirectDebitTitle } from "@components/PaymentMethods/DirectDebit";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useLocale } from "@hooks/useLocale";
import Wrapper, { Label, Title, ContactDetails, ContentWrapper } from "./PaymentMethod.style";

type PaymentMethodProps = {
  paymentType: PaymentMethodTypes;
  isRadio: boolean;
  isChecked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onNewPaymentMethod: (paymentMethod: CardPaymentMethod | DirectDebitPaymentMethod) => void;
};

export const PaymentMethod = ({
  paymentType,
  isRadio,
  isChecked,
  onChange,
  onNewPaymentMethod,
}: PaymentMethodProps): ReactElement => {
  const { data: orderData, paymentPlan } = useOrder();
  const { brand } = useBrand();
  const [selectedPaymentMethod] = useState<BasePaymentMethod>();
  const { t } = useTranslation(["page-order"]);
  const { locale } = useLocale();

  return (
    <Wrapper>
      {isRadio ? (
        <Input
          type="radio"
          id={paymentType}
          value={paymentType}
          onChange={onChange}
          name="paymentOptions"
          label={
            <Label>
              {paymentType === PaymentMethodTypes.CARD && <CardTitle />}
              {paymentType === PaymentMethodTypes.DIRECT_DEBIT && <DirectDebitTitle />}
              {paymentType === PaymentMethodTypes.INVOICE &&
                t("paymentAuthorisation.invoice.title", { context: brand })}
            </Label>
          }
          checked={isChecked}
          data-testid={`paymentMethod.${paymentType}`}
          hasMargin={false}
        />
      ) : (
        <Title>
          {paymentType === PaymentMethodTypes.CARD && <CardTitle />}
          {paymentType === PaymentMethodTypes.DIRECT_DEBIT && <DirectDebitTitle />}
          {paymentType === PaymentMethodTypes.INVOICE &&
            t("paymentAuthorisation.invoice.title", { context: brand })}
        </Title>
      )}

      {isChecked && (
        <ContentWrapper>
          <>
            {/* Invoice */}
            {paymentType === PaymentMethodTypes.INVOICE && (
              <>
                <p data-testid={`paymentMethod.${paymentType}.supportingText`}>
                  {t("paymentAuthorisation.invoice.supportingText", { context: brand })}
                </p>
                <ContactDetails data-testid="paymentMethod.invoice.details">
                  <dt>{t("paymentAuthorisation.invoice.labels.email")}</dt>
                  <dd className="fs-mask">
                    {!orderData && !selectedPaymentMethod ? (
                      <Skeleton height={24} />
                    ) : (
                      orderData?.customer.invoice_address?.email || orderData?.customer.user.email
                    )}
                  </dd>
                  <dt>{t("paymentAuthorisation.invoice.labels.address", { context: brand })}</dt>
                  <dd className="fs-mask">
                    {!orderData && !selectedPaymentMethod ? (
                      <>
                        <Skeleton width={125} height={24} />
                        <br />
                        <Skeleton width={40} height={24} />
                        <br />
                        <Skeleton width={55} height={24} />
                        <br />
                        <Skeleton width={70} height={24} />
                        <br />
                        <Skeleton width={20} height={24} />
                        <br />
                      </>
                    ) : (
                      [
                        orderData?.customer.invoice_address?.address_line1,
                        orderData?.customer.invoice_address?.address_line2,
                        orderData?.customer.invoice_address?.address_line3,
                        orderData?.customer.invoice_address?.city,
                        orderData?.customer.invoice_address?.region,
                        orderData?.customer.invoice_address?.postcode,
                        orderData?.customer.invoice_address?.country,
                      ]
                        .filter((value: string | undefined): value is string => !!value)
                        .map((line) => (
                          <Fragment key={`delivery_address-${line}`}>
                            {line}
                            <br />
                          </Fragment>
                        ))
                    )}
                  </dd>
                </ContactDetails>
              </>
            )}

            {/* Card or Direct debit */}
            {paymentType !== PaymentMethodTypes.INVOICE && (
              <div>
                <p data-testid={`paymentMethod.${paymentType}.supportingText`}>
                  {t(
                    // eslint-disable-next-line no-nested-ternary
                    paymentType === PaymentMethodTypes.CARD
                      ? "paymentAuthorisation.card.form.supportingText"
                      : paymentPlan?.scheduled_payments.length === 1
                      ? "paymentAuthorisation.supportingText.noResults.singlePaymentDate"
                      : "paymentAuthorisation.supportingText.noResults.default",
                    {
                      context: brand,
                      dueDate: paymentPlan?.scheduled_payments[0].date
                        ? new Date(paymentPlan?.scheduled_payments[0].date).toLocaleDateString(
                            locale
                          )
                        : undefined,
                    }
                  )}
                </p>

                {paymentType === PaymentMethodTypes.CARD && (
                  <NewPaymentMethod
                    paymentType={paymentType}
                    onSuccess={onNewPaymentMethod}
                    isInline
                  />
                )}

                {paymentType === PaymentMethodTypes.DIRECT_DEBIT && (
                  <NewPaymentMethod
                    paymentType={paymentType}
                    onSuccess={onNewPaymentMethod}
                    isInline
                  />
                )}
              </div>
            )}
          </>
        </ContentWrapper>
      )}
    </Wrapper>
  );
};
