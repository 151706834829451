import styled from "styled-components";

export default styled.section`
  background: ${({ theme }) => theme.newColors.background.variant2};
  padding: clamp(1.3rem, 3.3vw, 1.5rem) clamp(1.5rem, 3.5vw, 1.7rem);
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentBlockNumberWrapper = styled.div<{ disabled: boolean }>`
  position: relative;
  width: 24px;
  height: 24px;
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? theme.newColors.text.variant2 : theme.newColors.text.variant3};
  background: ${({ theme, disabled }) =>
    disabled ? undefined : theme.newColors.background.variant3};
  border-radius: 100%;
`;

export const ContentBlockNumber = styled.div<{ disabled: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme, disabled }) =>
    disabled ? theme.newColors.text.variant2 : theme.newColors.text.variant3};
  font-size: ${({ theme }) => theme.typography.small.fontSize};
  line-height: ${({ theme }) => theme.typography.small.lineHeight};
`;

export const Title = styled.h2<{ disabled: boolean }>`
  font-size: ${({ theme }) => theme.typography.h5.fontSize};
  margin: 0 0 0 ${({ theme }) => theme.ratios.level3};
  color: ${({ theme, disabled }) =>
    disabled ? theme.newColors.text.variant2 : theme.newColors.text.variant1};
`;

export const EditButton = styled.button`
  color: ${({ theme }) => theme.newColors.text.variant2};
  margin-left: auto;
`;

export const Content = styled.div<{ complete: boolean; hasPadding: boolean }>`
  padding-top: ${({ theme, hasPadding }) => (hasPadding ? theme.ratios.level6 : undefined)};
  padding-left: ${({ theme, complete }) =>
    complete ? `calc(24px + ${theme.ratios.level3})` : undefined};
`;
