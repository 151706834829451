import styled, { keyframes } from "styled-components";
import { rgba } from "polished";

export const InputWrapper = styled.div`
  margin: ${({ theme }) => `-${theme.ratios.level4}`};
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.ratios.level7};

  & > span {
    // for skeleton
    width: 100%;
  }
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.red};
  font-weight: 500;

  * {
    color: ${({ theme }) => theme.colors.red};
  }
`;

export const ModalWrapper = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => rgba(theme.colors.grey, 0.7)};
  padding: 2rem 6vw;
  z-index: 998;
  overflow: auto;
`;

export const Modal = styled.div`
  position: relative;
  width: 100%;
  max-width: 39rem;
  background: ${({ theme }) => theme.colors.white};
  border: 3px solid ${({ theme }) => theme.colors.grey};
  border-radius: 2px;
  padding: 1rem;
  margin: auto;
  box-shadow: 1px 2px 7px -2px rgba(0, 0, 0, 0.2);
  z-index: 999;
`;

export const ModalContent = styled.div`
  margin: 1rem 0;
  text-align: center;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Loader = styled.div`
  margin: 1rem auto;
  border: 5px solid ${({ theme }) => rgba(theme.colors.greyLight, 0.7)};
  animation: spin 1s linear infinite;
  animation: ${spin} 1s linear infinite;
  border-top: 5px solid ${({ theme }) => theme.colors.greyDark};
  border-radius: 50%;
  width: 50px;
  height: 50px;
`;
