import { rgba } from "polished";
import styled, { css } from "styled-components";

type InputWrapperProps = {
  hasMargin: boolean;
};

export const InputWrapper = styled.div<InputWrapperProps>`
  margin: ${({ hasMargin }) => (hasMargin ? "1rem 0" : undefined)};
`;

const Toggle = styled.div`
  position: relative;
  height: 20px;
  flex-basis: 20px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 0.5rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.grey};
`;

export const Checkbox = styled(Toggle)`
  border-radius: 0.25rem;
`;

export const Radio = styled(Toggle)`
  border-radius: 50%;
`;

type LabelProps = {
  type: string;
  error?: boolean;
  hasPadding: boolean;
};

export const Label = styled.label<LabelProps>`
  display: flex;
  align-items: center;
  vertical-align: middle;
  font-size: 1rem;
  transition: color 0.2s ease-in-out;
  padding: ${({ theme, hasPadding }) => (hasPadding ? theme.ratios.level4 : undefined)};
  cursor: pointer;

  ${({ error, theme }) =>
    error &&
    css`
      color: ${theme.colors.red};
    `}

  ${({ type }) =>
    !["radio", "checkbox"].includes(type) &&
    css`
      flex-direction: column;
      align-items: flex-start;
    `}
`;

type InputProps = {
  error?: boolean;
  isSelect?: boolean;
};

export const Input = styled.input<InputProps>`
  border: 1px solid ${({ theme, error }) => (error ? theme.colors.red : "#DDDDDD")};
  border-radius: 2px;
  padding: 0.6rem 0.5rem;
  margin-top: 0.3rem;
  width: 100%;
  font-size: 1rem;
  transition: color 0.2s ease-in-out, border 0.2s ease-in-out, background-color 0.4s ease-in-out;

  ${({ error, theme }) =>
    error &&
    css`
      background: ${rgba(theme.colors.red, 0.04)};
      color: ${theme.colors.red};
    `}

  ${({ isSelect }) =>
    isSelect &&
    css`
      padding-right: 1.5rem;
      -webkit-appearance: none;
      -moz-appearance: none;
    `}
      
  ${({ type }) =>
    (type === "checkbox" || type === "radio") &&
    css`
      opacity: 0;
      width: 0;
      height: 0;
      padding: 0;
      margin: 0;
      clip: rect(0 0 0 0);

      .tabbing & {
        &:focus + ${Toggle} {
          box-shadow: 0 0 2px 2px #51a7e8;
        }
      }

      &:checked + ${Toggle} {
        border-color: ${({ theme }) => theme.colors.text};

        &:after {
          content: "";
          position: absolute;
          width: 4px;
          height: 10px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, calc(-50% - 1px)) rotate(45deg);
          border: 2px solid ${({ theme }) => theme.colors.text};
          border-left: none;
          border-top: none;
        }
      }
    `}

  ${({ type }) =>
    type === "radio" &&
    css`
      &:checked + ${Toggle} {
        &:after {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          background: ${({ theme }) => theme.colors.text};
        }
      }
    `}
`;

export const SelectWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const DropdownArrow = styled.div`
  position: absolute;
  top: 50%;
  right: 0.5rem;
  width: 0;
  height: 0;
  border-left: 0.3rem solid transparent;
  border-right: 0.3rem solid transparent;
  border-top: 0.4rem solid ${({ theme }) => theme.colors.text};
`;

export const LabelText = styled.span`
  flex: 1;
  font-size: 1rem;
  line-height: 1.2;
`;

export const ErrorMessage = styled.p`
  position: relative;
  line-height: 1;
  color: ${({ theme }) => theme.colors.red};
  margin: 0.4rem 0 0;
  padding-left: 1.05rem;
  font-size: 80%;

  &:before {
    content: "";
    position: absolute;
    width: 2rem;
    height: 2rem;
    border: 3px solid ${({ theme }) => theme.colors.red};
    border-radius: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%) scale(0.35);
    transform-origin: left;
  }

  &:after {
    content: "!";
    position: absolute;
    width: 2rem;
    line-height: 1;
    font-size: 210%;
    text-align: center;
    border: 3px solid transparent;
    left: 0;
    top: 50%;
    transform: translateY(-50%) scale(0.35);
    transform-origin: left;
  }
`;
