import { PaymentMethodTypes } from "@models/paymentMethods";

export const apiExtension = (paymentType: PaymentMethodTypes): string => {
  const endpoints = {
    [PaymentMethodTypes.CARD]: "stripe_payment_methods",
    [PaymentMethodTypes.DIRECT_DEBIT]: "gocardless_payment_methods",
    [PaymentMethodTypes.INVOICE]: "hokodo_payment_methods",
  };

  const endpoint = endpoints[paymentType];

  if (endpoint) {
    return endpoint;
  }

  throw Error("No endpoint available for passed payment type.");
};
