import { rgba } from "polished";
import styled from "styled-components";

export const ModalWrapper = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => rgba(theme.colors.grey, 0.7)};
  padding: 2rem 6vw;
  z-index: 998;
  overflow: auto;
`;

export const Modal = styled.div`
  position: relative;
  width: 100%;
  max-width: 39rem;
  background: ${({ theme }) => theme.colors.white};
  border: 3px solid ${({ theme }) => theme.colors.grey};
  border-radius: 2px;
  padding: 1rem;
  margin: auto;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: 999;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
  gap: calc(clamp(1.3rem, 3.3vw, 1.5rem) / 2);
`;

export const ModelCloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  width: 1.7rem;
  height: 1.7rem;
  padding: 0;
  transform: translate(45%, -45%);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  background: ${({ theme }) => theme.newColors.background.variant2};
  border: 1px solid ${({ theme }) => theme.newColors.background.variant4};
  font-weight: 400;
  font-family: Arial, sans-serif;
  line-height: 1;
  font-size: 1.5rem;
`;
