import { FC, useEffect, useRef, useState } from "react";
import { Brand, useBrand } from "@hooks/useBrand";
import { OrderSummary } from "@containers/OrderSummary";
import { CancelAndReturn } from "@containers/CancelAndReturn";
import { USP } from "@components/USP";
import { useRouter } from "next/router";
import Wrapper, { Main, Aside, UspMobile, UspDesktop } from "./WithSupportingContent.style";

export const WithSupportingContent: FC = ({ children }) => {
  const {
    query: { embedded },
  } = useRouter();
  const { brand } = useBrand();
  const [isAsideStuck, setIsAsideStuck] = useState(false);
  const asideElem = useRef(null);

  useEffect(() => {
    const cachedAsideElem = asideElem.current;
    const observer = new IntersectionObserver(([e]) => setIsAsideStuck(e.intersectionRatio < 1), {
      threshold: [1],
    });

    if (cachedAsideElem) {
      observer.observe(cachedAsideElem);
    }

    return () => {
      if (cachedAsideElem) {
        observer.unobserve(cachedAsideElem);
      }
    };
  }, [asideElem]);

  return (
    <Wrapper singleColumn={!!embedded}>
      {!embedded && (
        <Aside ref={asideElem} className={isAsideStuck ? "stuck" : undefined}>
          <div>
            <OrderSummary />
            <UspDesktop>
              <USP />
            </UspDesktop>
          </div>
        </Aside>
      )}
      <Main>
        {children}
        {!embedded && (
          <>
            <UspMobile>
              <USP />
            </UspMobile>
            {brand !== Brand.BOX && <CancelAndReturn />}
          </>
        )}
      </Main>
    </Wrapper>
  );
};
