import { Input } from "@components/Input";
import { FC, MouseEvent, useEffect, useMemo, useState } from "react";
import {
  loadStripe,
  StripeCardElement,
  StripeElementChangeEvent,
  StripeElementLocale,
  StripeError,
} from "@stripe/stripe-js";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { Brand, useBrand } from "@hooks/useBrand";
import { ErrorMessage, LabelText } from "@components/Input/Input.style";
import { ErrorLike, fetcher, fetcherWithToken } from "@utils/fetcher";
import { useOrderReference } from "@hooks/useOrderReference";
import useSWR from "swr";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useOrder } from "@hooks/useOrder";
import * as Sentry from "@sentry/nextjs";
import { useRouter } from "next/router";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { CardPaymentMethod } from "@models/paymentMethods";
import countries from "@public/data/countries.json";
import { useTranslation } from "next-i18next";
import { useRedirect } from "@hooks/useRedirect";
import { sendEvent } from "@utils/sendMessage";
import { theme } from "@styles/theme";
import { Button } from "@components/Button";
import { ButtonWrapper, CardWrapper, Columns, TextButton, StripeWrapper } from "./CardForm.style";

type FormProps = {
  onSuccess: (setupIntent: CardPaymentMethod) => void;
  onCancel?: (event: MouseEvent) => void;
  clientSecret: string;
};

type CardFormProps = Omit<FormProps, "clientSecret">;

interface FormValues {
  cardholderName: string;
  line1: string;
  line2: string;
  cityOrTown: string;
  region: string;
  postcode: string;
  country: string;
  default: boolean;
}

const Form: FC<FormProps> = ({ onSuccess, onCancel, clientSecret }) => {
  const { t } = useTranslation(["page-order", "common"]);
  const { locale } = useRouter();
  const { organisationId } = useOrder();
  const { key } = useOrderReference();

  const { brand } = useBrand();

  const stripe = useStripe();

  const elements = useElements();

  const [stripeError, setStripeError] = useState<StripeError>();
  const [isStripeCardComplete, setIsStripeCardComplete] = useState(false);
  const [isStripeProcessing, setIsStripeProcessing] = useState(false);
  const [isStripeElementLoading, setIsStripeElementLoading] = useState(true);
  const [countryCode, setCountryCode] = useState("");

  const { setFieldValue, ...formik } = useFormik({
    enableReinitialize: true,
    initialValues: {
      cardholderName: "",
      line1: "",
      line2: "",
      cityOrTown: "",
      region: "",
      postcode: "",
      country: countryCode,
      default: false,
    },
    validationSchema: Yup.object({
      cardholderName: Yup.string().required(
        t("common:isARequiredField", {
          label: t("paymentAuthorisation.card.form.cardDetails.labels.cardholdersName"),
          context: brand,
        })
      ),
      line1: Yup.string().required(
        t("common:isARequiredField", {
          label: t("paymentAuthorisation.card.form.billingAddress.labels.line1"),
          context: brand,
        })
      ),
      line2: Yup.string(),
      cityOrTown: Yup.string().required(
        t("common:isARequiredField", {
          label: t("paymentAuthorisation.card.form.billingAddress.labels.cityOrTown"),
          context: brand,
        })
      ),
      region: Yup.string().required(
        t("common:isARequiredField", {
          label: t("paymentAuthorisation.card.form.billingAddress.labels.region"),
          context: brand,
        })
      ),
      postcode: Yup.string().when("country", {
        is: "GB",
        then: Yup.string()
          .matches(
            /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/,
            t("common:pleaseEnterAValid", {
              label: t("paymentAuthorisation.card.form.billingAddress.labels.postcode"),
              context: brand,
            })
          )
          .required(
            t("common:isARequiredField", {
              label: t("paymentAuthorisation.card.form.billingAddress.labels.postcode"),
              context: brand,
            })
          ),
        otherwise: Yup.string().required(
          t("common:isARequiredField", {
            label: t("paymentAuthorisation.card.form.billingAddress.labels.postcode"),
            context: brand,
          })
        ),
      }),
      country: Yup.string().required(
        t("common:isARequiredField", {
          label: t("paymentAuthorisation.card.form.billingAddress.labels.country"),
          context: brand,
        })
      ),
      default: Yup.boolean(),
    }),
    onSubmit: async (values: FormValues) => {
      if (!stripe || !elements || !isStripeCardComplete) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      if (stripeError) {
        elements?.getElement("card")?.focus();
        return;
      }

      if (isStripeCardComplete) {
        setIsStripeProcessing(true);
      }

      const address: Record<string, string> = {
        line1: values.line1,
        line2: values.line2,
        state: values.region,
        country: values.country,
        city: values.cityOrTown,
        postal_code: values.postcode,
      };

      Object.keys(address).forEach((objectKey) => !address[objectKey] && delete address[objectKey]);

      // TODO: Add email to billing details
      const payload = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement) as StripeCardElement,
          billing_details: {
            address,
            name: values.cardholderName,
          },
        },
      });

      if (payload.error) {
        Sentry.withScope((scope) => {
          const err =
            payload.error.code || payload.error.decline_code || payload.error.message || "";
          scope.setFingerprint(["stripe", err]);
          scope.setLevel(Sentry.Severity.Warning);
          Sentry.captureMessage(err);
        });

        setStripeError(payload.error);
        setIsStripeProcessing(false);
      } else if (!organisationId) {
        // TODO: Better handling of errors
        Sentry.withScope((scope) => {
          const err = "Organisation ID is missing";
          scope.setFingerprint(["stripe", err]);
          Sentry.captureException(new Error(err));
        });

        setStripeError({
          type: "invalid_request_error",
          message: t("paymentAuthorisation.card.form.errorMessages.failedToSaveCardAgainstOrg", {
            context: brand,
          }),
        });
        setIsStripeProcessing(false);
      } else if (payload.setupIntent && organisationId) {
        // TODO: Better handling of errors
        // TODO: use fetcherWithToken
        const data = await fetcher<CardPaymentMethod>(
          `/organisations/${organisationId}/stripe_payment_methods`,
          {
            method: "POST",
            headers: new Headers({
              authorization: `OrderKey ${key}`,
              "content-type": "application/json",
            }),
            body: JSON.stringify({
              intent_id: payload.setupIntent.id,
              is_default: values.default,
            }),
          }
        ).catch((err) => {
          Sentry.captureException(err);

          setStripeError({
            type: "api_error",
            message: t("paymentAuthorisation.card.form.errorMessages.failedToSaveCardAgainstOrg", {
              context: brand,
            }),
          });
        });

        setIsStripeProcessing(false);

        if (onSuccess && data) {
          onSuccess(data);
        }
      }
    },
  });

  useEffect(() => {
    if (!isStripeCardComplete && formik.submitCount) {
      setStripeError({
        type: "card_error",
        message: t("common:isARequiredField_plural", {
          label: t("paymentAuthorisation.card.form.cardDetails.labels.card", {
            context: brand,
          }),
          context: brand,
        }),
      });
    }
  }, [formik.submitCount, isStripeCardComplete, t, brand]);

  useEffect(() => {
    const matchingCountries = locale
      ? countries.filter(({ languages }) => languages.includes(locale))
      : [];

    if (matchingCountries?.length === 1) {
      void setCountryCode(matchingCountries[0].code);
    }
  }, [setFieldValue, locale]);

  return (
    <form method="post" onSubmit={formik.handleSubmit} data-testid="cardForm">
      <fieldset>
        <legend data-testid="cardForm.legend.cardDetails">
          {t("paymentAuthorisation.card.form.cardDetails.title", { context: brand })}
        </legend>
        <Input
          hasPadding={false}
          small
          id="cardholderName"
          data-testid="cardForm.name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.cardholderName}
          error={formik.touched.cardholderName ? formik.errors.cardholderName : undefined}
          label={t("paymentAuthorisation.card.form.cardDetails.labels.cardholdersName", {
            context: brand,
          })}
        />

        <LabelText>
          <small>
            {t("paymentAuthorisation.card.form.cardDetails.labels.card", { context: brand })}
          </small>
        </LabelText>
        <CardWrapper error={!!stripeError}>
          {isStripeElementLoading && <Skeleton height={19.9} />}
          <StripeWrapper data-testid="cardForm.stripeWrapper" isLoading={isStripeElementLoading}>
            <CardElement
              options={{
                style: {
                  base: {
                    fontStyle: "normal",
                    fontSize: "16px",
                    fontFamily: '"DM Sans", Helvetica, Arial, sans-serif',
                    color: theme.colors.text,
                  },
                  invalid: {
                    iconColor: theme.colors.red,
                    color: theme.colors.red,
                  },
                },
                hidePostalCode: true,
              }}
              onChange={(event: StripeElementChangeEvent) => {
                setStripeError(event.error);
                setIsStripeCardComplete(event.complete);
              }}
              onReady={() => {
                setIsStripeElementLoading(false);
              }}
              onBlur={() => {
                if (!isStripeCardComplete) {
                  setStripeError({
                    type: "validation_error",
                    message: t("common:isARequiredField_plural", {
                      label: t("paymentAuthorisation.card.form.cardDetails.labels.card", {
                        context: brand,
                      }),
                      context: brand,
                    }),
                  });
                }
              }}
            />
          </StripeWrapper>
        </CardWrapper>
        {stripeError && <ErrorMessage>{stripeError.message}</ErrorMessage>}
      </fieldset>
      <fieldset>
        <legend data-testid="cardForm.legend.billingAddress">
          {t("paymentAuthorisation.card.form.billingAddress.title", { context: brand })}
        </legend>
        <Input
          hasPadding={false}
          small
          id="line1"
          data-testid="cardForm.line1"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.line1}
          error={formik.touched.line1 ? formik.errors.line1 : undefined}
          label={t("paymentAuthorisation.card.form.billingAddress.labels.line1", {
            context: brand,
          })}
        />
        <Input
          hasPadding={false}
          small
          id="line2"
          data-testid="cardForm.line2"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.line2}
          error={formik.touched.line2 ? formik.errors.line2 : undefined}
          label={t("paymentAuthorisation.card.form.billingAddress.labels.line2", {
            context: brand,
          })}
        />
        <Columns>
          <Input
            hasPadding={false}
            small
            id="cityOrTown"
            data-testid="cardForm.cityOrTown"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.cityOrTown}
            error={formik.touched.cityOrTown ? formik.errors.cityOrTown : undefined}
            label={t("paymentAuthorisation.card.form.billingAddress.labels.cityOrTown", {
              context: brand,
            })}
          />
          <Input
            hasPadding={false}
            small
            id="region"
            data-testid="cardForm.region"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.region}
            error={formik.touched.region ? formik.errors.region : undefined}
            label={t("paymentAuthorisation.card.form.billingAddress.labels.region", {
              context: brand,
            })}
          />
        </Columns>
        <Columns>
          <Input
            hasPadding={false}
            small
            id="postcode"
            data-testid="cardForm.postcode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.postcode}
            error={formik.touched.postcode ? formik.errors.postcode : undefined}
            label={t("paymentAuthorisation.card.form.billingAddress.labels.postcode", {
              context: brand,
            })}
          />
          <Input
            hasPadding={false}
            type="select"
            small
            id="country"
            data-testid="cardForm.country"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.country}
            error={formik.touched.country ? formik.errors.country : undefined}
            label={t("paymentAuthorisation.card.form.billingAddress.labels.country", {
              context: brand,
            })}
          >
            <option value="">{t("common:pleaseSelect", { context: brand })}</option>
            {countries.map(({ code, name }) => (
              <option value={code} key={code}>
                {name}
              </option>
            ))}
          </Input>
        </Columns>
      </fieldset>
      <Input
        hasPadding={false}
        id="default"
        data-testid="cardForm.default"
        type="checkbox"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        checked={formik.values.default}
        error={formik.touched.default ? formik.errors.default : undefined}
        label={t("paymentAuthorisation.card.form.labels.setAsDefaultPaymentMethod", {
          context: brand,
        })}
      />
      <ButtonWrapper>
        {onCancel && (
          <TextButton type="button" onClick={onCancel} data-testid="cardForm.cancel">
            {t("common:cancel")}
          </TextButton>
        )}
        <Button
          type="submit"
          disabled={isStripeProcessing || !stripe || !elements}
          data-testid="cardForm.submit"
        >
          {isStripeProcessing
            ? `${t("common:loading", { context: brand })}...`
            : t("paymentAuthorisation.card.form.addCard", { context: brand })}
        </Button>
      </ButtonWrapper>
    </form>
  );
};

export const CardForm: FC<CardFormProps> = ({ onSuccess, onCancel }) => {
  const { t } = useTranslation(["common"]);
  const { data: orderData, organisationId } = useOrder();
  const { key } = useOrderReference();
  const { brand } = useBrand();
  const { redirect } = useRedirect();
  const { query, locale } = useRouter();

  const { data } = useSWR<
    { id: string; client_secret: string; publishable_key: string },
    ErrorLike
  >(
    () =>
      organisationId && orderData?.customer.user.id
        ? `/organisations/${organisationId}/stripe_payment_methods/setup_intent`
        : null,
    fetcherWithToken(key, {
      method: "POST",
      body: JSON.stringify({ user: orderData?.customer.user.id }),
    }),
    {
      onError: async (err) => {
        // TODO: remove repeated error logic
        Sentry.captureException(err);

        try {
          await Sentry.flush(2000);
        } catch (sentryFlushErr) {
          console.error("Sentry failed to complete network requests");
        }

        if (query.embedded) {
          // TODO: provide failure reason
          sendEvent("failure");
        } else {
          // TODO: find a better way to handle this for telesales
          void redirect(
            brand === Brand.BOX ? "/error" : orderData?.payment_offer?.urls.failure || "/error"
          );
        }
      },
      revalidateOnFocus: false,
    }
  );

  const stripe = useMemo(
    () => (data?.publishable_key ? loadStripe(data.publishable_key) : null),
    [data?.publishable_key]
  );

  return !data ? (
    // TODO: Implement loading UI
    <div style={{ marginTop: "2rem" }}>{`${t("common:loading", { context: brand })}...`}</div>
  ) : (
    <Elements
      stripe={stripe}
      options={{
        fonts: [
          {
            cssSrc: "https://fonts.googleapis.com/css?family=DM+Sans",
          },
        ],
        locale: (locale as StripeElementLocale) || "auto",
      }}
    >
      <Form onSuccess={onSuccess} onCancel={onCancel} clientSecret={data.client_secret} />
    </Elements>
  );
};
