import styled, { css } from "styled-components";
import { rgba } from "polished";

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 1.5rem;
`;

type CardWrapperProps = {
  error: boolean;
};

export const CardWrapper = styled.div<CardWrapperProps>`
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme, error }) => (error ? theme.colors.red : "#DDDDDD")};
  border-radius: 2px;
  padding: 0.594rem 0.5rem;
  margin-top: 0.3rem;
  width: 100%;
  transition: border 0.2s ease-in-out, background-color 0.4s ease-in-out;

  ${({ error, theme }) =>
    error &&
    css`
      background: ${rgba(theme.colors.red, 0.04)};
    `}
`;

export const Columns = styled.div`
  display: flex;
  margin: 1rem 0 1rem -1rem;

  & > div {
    flex: 1;
    margin: 0 0 0 1rem;

    &:first-child {
      margin-top: 0;
    }
  }
`;

export const TextButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  margin: 0;
  padding: 0;
  text-transform: none;
  color: inherit;
  border: none;

  & span:first-child {
    font-size: 2rem;
    margin-right: 0.4rem;
  }

  & + button {
    margin-left: 2rem;
  }
`;

type StripeWrapperProps = {
  isLoading: boolean;
};

export const StripeWrapper = styled.div<StripeWrapperProps>`
  ${({ isLoading }) =>
    isLoading &&
    css`
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
    `}
`;
